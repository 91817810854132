export const reloadWithTurboLinks = () =>  {
    let position = [window.scrollX, window.scrollY];

    document.addEventListener('turbolinks:load', () => {
        window.scrollTo.apply(window, position);
    });
    
    Turbolinks.visit(window.location);
}

export const handleFlashMessage = () => {
   
    const msg = document.querySelector('.notice');
    const alert = document.querySelector(".alert");
    if (msg) {
        setTimeout(function()  {
            msg.remove();
        }, 4000);
    }

    if (alert) {
        setTimeout(function()  {
            alert.remove();
        }, 4000);
    }
}

 export const handleConfirmationMessage = () => {
    setTimeout(function() {
        const container = document.querySelector('.modale-new') ? document.querySelector('.modale-new') : document.querySelector('.modale-new-scroll') ;
        const overlay = document.querySelector('.overlay');
        container.setAttribute('style', 'display: none');
        overlay.setAttribute('style', 'display: none');
    }, 1200);
}

/* export const handleErrorMessage = () => {
    const error = document.querySelector('.error')
    setTimeout(function() {
        error.remove()
    }, 1500)
} */
