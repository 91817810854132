import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["menu", "menuButton", "openMenuIcon", "closeMenuIcon", "mapLink", "rassemblementLink", "documentsLink", "referentsLink", "mentionsLink",
                    "map", "accessDrawsLink", "floorBox", "accessDrawLayout"] ;

    connect() {

        this.appState = {
            currentPage: 'map-page',
            pages: ['map-page', 'rassemblement-page', 'documents-page', 'referents-page', 'mentions-page', 'access-draws-page'],
            isMenuOpen: false,
            isMapExpanded: false,
            isRassemblementExpanded: false,
            currentlyPlayingVideo: null
          };

        this.DOMElements = {
          menu: {
            wrapper: this.menuTarget,
            btn: this.menuButtonTarget,
            openIcn: this.openMenuIconTarget,
            closeIcn: this.closeMenuIconTarget,
            links: [
              this.mapLinkTarget,
              this.rassemblementLinkTarget,
              this.documentsLinkTarget,
              this.referentsLinkTarget,
              this.mentionsLinkTarget,
              this.accessDrawsLinkTarget
            ],
          },
    
          pages: [

            {
              mapShow: {
                element: this.mapTarget,
                callback: () => {
                  const element = this.mapTarget;
                  if (this.appState.isMapExpanded) {
                    this.mapTarget.classList.remove('map--expanded');
                  } else {
                    this.mapTarget.classList.add('map--expanded');
                  }
        
                  this.appState.isMapExpanded = !this.appState.isMapExpanded;
                },
              },

              videoBtns: [

                document.getElementById('incendie-btn') && {
                  element: document.getElementById('incendie-btn'),
                  callback: (event) => this.playVideo('incendie', event.currentTarget.dataset.video )
                },

                document.getElementById('evacuation-btn') && {
                  element: document.getElementById('evacuation-btn'),
                  callback: (event) => this.playVideo('evac', event.currentTarget.dataset.video )
                },

                document.getElementById('accident-btn') && {
                  element: document.getElementById('accident-btn'),
                  callback: (event) => this.playVideo('accident', event.currentTarget.dataset.video )
                },

                document.getElementById('transfert-btn') && {
                  element: document.getElementById('transfert-btn'),
                  callback: (event) => this.playVideo('evac', event.currentTarget.dataset.video )
                },

                document.getElementById('vigipirate-btn') && {
                  element: document.getElementById('vigipirate-btn'),
                  callback: (event) => this.playVideo('vigipirate', event.currentTarget.dataset.video )
                }
              ],
              emergencyBtn: {
                element: document.querySelector('.emergency__btn'),
                callback: () => {
                  window.location.href = 'tel:112';
                },
              },
            },
            {
              picture: {
                element: document.querySelector('.rassemblement-picture'),
                callback: () => {
                  const element = document.querySelector('.rassemblement-picture');
                  if (this.appState.isRassemblementExpanded) {
                    element.classList.remove('rassemblement-picture--expanded');
                  } else {
                    element.classList.add('rassemblement-picture--expanded');
                  }
        
                  this.appState.isRassemblementExpanded = !this.appState.isRassemblementExpanded;
                },
              },
            },
            {},
            {},
            {},
            { 
              accessDraw: {
                element: document.querySelectorAll('.access-draws-container__list__item'),
                callback: (event) => {
                  const url = event.currentTarget.dataset.media;
                 
                 
                  this.accessDrawLayoutTarget.classList.add('access-draws-layout--opened');
                  this.accessDrawLayoutTarget.innerHTML = `<img src=${url} alt="access-draw-plan"/>`;
                  this.accessDrawLayoutTarget.addEventListener( 'click', () => {
                    this.accessDrawLayoutTarget.classList.remove('access-draws-layout--opened');
                  })
                  
                }
              }
            }
          ],
        };

        this.DOMElements.menu.btn.addEventListener('click', this.toggleMenuBtn);
        this.DOMElements.menu.links.forEach((link, index) => {
            link.addEventListener('click', () => {
              
              const { currentPage, pages } = this.appState;

              if (currentPage !== pages[index]) {
                this.switchPage(pages[index]);
              }
          
              if (this.appState.isMenuOpen) {
                const { wrapper, openIcn, closeIcn } = this.DOMElements.menu;
          
                wrapper.classList.remove('menu--open');
                closeIcn.classList.add('header__bottom__btn__img--hidden');
                openIcn.classList.remove('header__bottom__btn__img--hidden');
                this.appState.isMenuOpen = false;
              }
            });
        });

        this.initPage('map-page');
    }

  

    toggleMenuBtn = () => {
        const { wrapper, openIcn, closeIcn } = this.DOMElements.menu;
      
        if (this.appState.isMenuOpen) {
          wrapper.classList.remove('menu--open');
          closeIcn.classList.add('header__bottom__btn__img--hidden');
          openIcn.classList.remove('header__bottom__btn__img--hidden');
          this.appState.isMenuOpen = false;
        } else {
          wrapper.classList.add('menu--open');
          openIcn.classList.add('header__bottom__btn__img--hidden');
          closeIcn.classList.remove('header__bottom__btn__img--hidden');
          this.appState.isMenuOpen = true;
        }
    };

    initPage = (page) => {
      
        const pageIndex = this.appState.pages.indexOf(page);
        const pageElements = Object.keys(this.DOMElements.pages[pageIndex]);

        pageElements.forEach((pageElement) => {
          const DOMElement = this.DOMElements.pages[pageIndex][pageElement];
          if(DOMElement.element && DOMElement.element.length) {
            DOMElement.element.forEach( el => el.addEventListener('click', DOMElement.callback) );
          }
          else if (DOMElement.element && !(DOMElement.element instanceof NodeList)) {
            DOMElement.element.addEventListener('click', DOMElement.callback);
          } 
          else if(!(DOMElement.element instanceof NodeList)){
            DOMElement.forEach((item) =>  item && item.element.addEventListener('click', item.callback));
          }
        });
    };

    switchPage = (page) => {

        const previousPage = document.getElementById(this.appState.currentPage);
        const nextPage = document.getElementById(page);
        
        previousPage.classList.remove('show-draw-content__page--open');
        nextPage.classList.add('show-draw-content__page--open');
      
        this.appState.currentPage = page;
        this.initPage(page);
    };

    playVideo = (typeVideo, video) => {
        const videoLayout = document.querySelector('.video-layout');
        const closeBtn = document.getElementById('close-video');
        const videoElement = document.getElementById('video');
      
        document.getElementById('video-source').setAttribute('src', `${video}`);

      
        this.appState.currentlyPlayingVideo = typeVideo;
      
        videoElement.load();
      
      /*   videoElement.addEventListener('loadedmetadata', () => {
          const tracks = videoElement.querySelectorAll('track');
      
          tracks.forEach((track) => {
            switch (track.getAttribute('label')) {
              case 'FR':
                track.setAttribute('srclang', 'fr');
                break;
              case 'EN':
                track.setAttribute('srclang', 'en');
                break;
              default:
                break;
            }
          });
        }); */
      
        videoElement.addEventListener('ended', this.closeVideo);
        closeBtn.addEventListener('click', this.closeVideo);
      
        videoLayout.querySelector('.video-container').classList.add(`video-container--${typeVideo}`);
        closeBtn.classList.add(`close-btn--${typeVideo}`);
        videoLayout.classList.add('video-layout--opened');
    };

    closeVideo = () => {
        const videoLayout = document.querySelector('.video-layout');
        const closeBtn = document.getElementById('close-video');
        const videoElement = document.getElementById('video');
      
        videoElement.pause();
      
        document.getElementById('video-source').removeAttribute('src');
      
        videoElement.removeEventListener('ended', this.closeVideo);
        closeBtn.removeEventListener('click', this.closeVideo);
      
        videoLayout.querySelector('.video-container').classList.remove(`video-container--${this.appState.currentlyPlayingVideo}`);
        closeBtn.classList.remove(`close-btn--${this.appState.currentlyPlayingVideo}`);
      
        this.appState.currentlyPlayingVideo = null;
        videoLayout.classList.remove('video-layout--opened');
      };

      handleSelectedFloor = (event) => {
        const key = event.currentTarget.dataset.key;
        const drawId = event.currentTarget.dataset.drawId;
        const planIndex = event.currentTarget.dataset.planIndex;
        const selectedBox = event.currentTarget;
       
        Rails.ajax({
          type: "get",
          url: `/draws/${drawId}/get_asset_plan`,
          data: new URLSearchParams({
            key: key,
            plan_index: planIndex
          }),
          success: (data) => {
            this.mapTarget.innerHTML = data.html;
            this.floorBoxTargets.forEach((box) => {
              box.classList = ("infos-bar__select-floor-container__floor")
            })
            selectedBox.classList.add("infos-bar__select-floor-container__floor--selected");
          },
          error: (data) => console.log(data)
        })
    }
}