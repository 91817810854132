import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {

    static targets = ["dropDown", "modaleSearchResult", "overlayNavbar", "containerSearchResult", "searchContent"];

    connect() {

    }

    showDropdown = (event) => {
        this.dropDownTarget.style.display = "block";
        window.addEventListener("click", () => {
            this.dropDownTarget.style.display = "none";
        });
        event.stopPropagation();
    }


    //searchMethod

    openSearchModale = (event) => {
        this.modaleSearchResultTarget.style.display = "flex"
        this.modaleSearchResultTarget.classList.add('modale-search-result--displayed');
        this.overlayNavbarTarget.style.display = "block";

        this.overlayNavbarTarget.addEventListener("click", (event) => {
            event.currentTarget.style.display = "none";
            this.modaleSearchResultTarget.style.display = "none";
        })
    }

    appendSearch = (event) => {
       

        const [_data, _status, xhr] = event.detail;
        this.modaleSearchResultTarget.innerHTML = xhr.response;
       

    }

    launchSearch = () => {

        let query = document.getElementById('query').value;

        if(query.length >= 2) {
            
            Rails.ajax({
                type: 'get',
                url: '/search_entries/index',
                data: new URLSearchParams({
                    query: query
                }),
                success: (data) => {
                    this.containerSearchResultTarget.innerHTML = data.html;
                },
                error: (data) => console.log(data)
            })
        } else {
            this.containerSearchResultTarget.innerHTML = '';
        }
    }

    deleteSearchContent = () => {
        this.searchContentTarget.value = "";
        this.containerSearchResultTarget.innerHTML = "";
    }
}