import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { handleConfirmationMessage } from "../utils/reload.js";
import { openCollapse, updateModalSize } from "../utils/tools.js";

export default class extends Controller {

    static targets = ["dropDown", "overlay", "modaleNew", "buttonClose", "retailersWrapper", "referentsWrapper", "editReferentModale", "buttonCloseEditReferent", "formReferent",
    "containerFormReferent", "buttonAddReferent", "blocOrganizationData"];

    connect() {
    }

    showEditOrganization = (event) => {
        const token = event.currentTarget.dataset.organizationToken;
        const page = window.location.href;
        
        Rails.ajax({
            type: 'get',
            url: `/organizations/${token}/edit`,
            success: (data) => this.display(data, this.modaleNewTarget),
            error: (data) => { console.log(data)}
            })
    }

    updateOrganization = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.blocOrganizationDataTarget.innerHTML = xhr.response;
        this.displaySuccessMessage("Organisation", "modifiée")
    }

    showNewRetailer = (event) => {
        const token = event.currentTarget.dataset.organizationToken;

        Rails.ajax({
            type: 'get',
            url: `/organizations/${token}/retailers/new`,
            success: (data) => this.display(data, this.modaleNewTarget),
            error: (data) => { console.log(data) }
        });
    }

    showEditRetailer = (event) => {;
        const id = event.currentTarget.id

        Rails.ajax({
            type: 'get',
            url: `/retailers/${id}/edit`,
            success: (data) => this.display(data, this.modaleNewTarget),
            error: (data) => { console.log(data) }
        });
    }

    showOptionReferents = (event) => {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");

        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            element.scrollIntoView({behavior: "smooth", block: 'end'});
        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)'); 
        }
        updateModalSize(this.modaleNewTarget);
    }

    showNewReferent = (event) => {
        const id = event.currentTarget.id;
        Rails.ajax({
            type: 'get',
            url: `/retailers/${id}/referents/new`,
            success: (data) => this.display(data, this.modaleNewTarget),
            error: (data) => { console.log(data) }
        });
    }

    showEditReferent = (event) => {
        const id = event.currentTarget.dataset.referent;
        Rails.ajax({
            type: 'get',
            url: `/referents/${id}/edit`,
            success: (data) => this.display(data, this.modaleNewTarget),
            error:  (data) => { console.log(data) }
        });
    }

    displayFormReferent = (event) => {
        this.buttonAddReferentTarget.remove()
        this.formReferentTarget.setAttribute('style', 'display: flex');
        this.formReferentTarget.scrollIntoView({behavior: "smooth", block: 'end'});

    }

    addFormReferent = (event) => {
        event.currentTarget.remove();
        const index = document.querySelectorAll('.wrapper-form-element__block').length
        
        Rails.ajax({
            type: 'get',
            url: `/referents/generate_new_referent_form/${index}`,
            success: (data) => {
                this.formReferentTarget.insertAdjacentHTML('beforeend', data.html);
                this.formReferentTarget.scrollIntoView({behavior: "smooth", block: 'end'});
            },
            error: (data) => { console.log(data) }
        });
        
    }

  
    display(data, container) {
        this.overlayTarget.setAttribute('style', 'display: flex');
        container.setAttribute('style', 'display: flex');
        container.classList = 'modale-new';
        if(data.html) {
            container.innerHTML = data.html;
        } else {
            container.innerHTML = data;
        }

        this.triggerCloseButton();
        this.triggerOverlay();

      

        if(container.querySelector('form')) {
            const form = container.querySelector('form').dataset.container;
        
            switch (form) {
                case "form-edit-organization":
                case "form-edit-retailer":
                    container.classList.add('modale-new--small');
                break;
                default:
                    container.classList = 'modale-new';
            }
        }
    }

    displayEditReferent(data) {
        this.editReferentModaleTarget.setAttribute('style', 'display: flex');
        this.editReferentModaleTarget.innerHTML = data.html;

        this.buttonCloseEditReferentTarget.addEventListener("click", (event) => {
            this.editReferentModaleTarget.setAttribute('style', 'display: none');
        });

    }

    displayReferent(data) {
        this.editReferentModaleTarget.setAttribute('style', "display: flex");
        this.editReferentModaleTarget.innerHTML = data.html;
    }

    appendRetailer = (event) => {
        const [_data, _status, xhr] = event.detail
        const element = xhr.response;
        this.retailersWrapperTarget.insertAdjacentHTML('afterbegin', element)
        this.displaySuccessMessage("Revendeur", "créé");
    }

    updateRetailer = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.retailersWrapperTarget.outerHTML = xhr.response;

        this.modaleNewTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>Revendeur mis à jour</h3>
        </div>
        `;
        this.modaleNewTarget.classList = "modale-new modale-new--small";
        this.displaySuccessMessage("Revendeur", "modifé");
    }

    appendReferent = () => {
        this.modaleNewTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>Revendeur crée</h3>
        </div>
        `;
        handleConfirmationMessage();
    }

    appendEditReferent = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.modaleNewTarget.innerHTML = xhr.response;
        this.modaleNewTarget.querySelector('.collapse__wrapper').style.display = 'flex';
        this.modaleNewTarget.classList = "modale-new";
        this.triggerCloseButton();
    }

    checkForReferentInputs = (event) => {
        const [_data, _status, xhr] = event.detail;
        if(_status.type != 'DELETE') {
            const allInputs = this.formReferentTarget.querySelectorAll('input');
            if(allInputs.length > 0) {
                allInputs.forEach((input) => {
                    if(!input.value) {
                        input.classList.add("js-error");
                        input.placeholder = "Veuillez remplir cet élément";
                        event.preventDefault();
                    }
                });
            }
        }
    }


    error = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.modaleNewTarget.innerHTML = xhr.response;
        this.triggerCloseButton();
        openCollapse();
    }

    errorEditRetailer = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.modaleNewTarget.innerHTML = xhr.response;
        this.modaleNewTarget.classList = "modale-new modale-new--small";
        this.triggerCloseButton();
   
        openCollapse();
        document.querySelectorAll('.validation').forEach((el) => {
            el.classList.add('input-error')
            if (el.querySelector('input').placeholder == "Veuillez remplir ce champ") {
            }
        });
    }

    errorEditReferent = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.editReferentModaleTarget.innerHTML = xhr.response;
    }

    errorDestroyReferent = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.display(xhr.response, this.modaleNewTarget)
        this.modaleNewTarget.classList.add('modale-confirmation-delete');
    }



    triggerCloseButton() {
        this.buttonCloseTarget.addEventListener("click", () => {
            this.modaleNewTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
    }

    triggerOverlay() {
        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            this.modaleNewTarget.setAttribute('style','display: none');
        });
    }

    displaySuccessMessage = (item, action) => {


        this.modaleNewTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleNewTarget.classList.add('modale-new--small');

        this.modaleNewTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>${item} ${action}</h3>
        </div>
        `;
        handleConfirmationMessage();
    }

}