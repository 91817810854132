import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { handleConfirmationMessage , reloadWithTurboLinks } from "../utils/reload.js"
import { disabledCreateButton, enabledCreateButton, handleCheckbox, openCollapse, getUrlParams } from "../utils/tools.js";
import { drawChart } from "../pages/dashboard.js"


/**
 * Contains all the functions relative to the customers show page.
 * @class
 * @alias DashboardsController
 */
export default class extends Controller { 
    static targets = [  "overlay", "buttonClose", "modaleNewDashboard", "customersContainer", "usersContainer", "usersCounterContainer", "projectsContainer",
                        "customersCounterContainer", "projectsCounterContainer", "resetFilterButtonUser", "customersCount", "multipleButtons", 'preview', 'projectsCount', "disclaimer",
                        "addProject", "addCustomer", "resetFilterButtonCustomer", "modaleSearchResult", "multiCheckbox", "disclaimerProject", "rangeDate", "chevron", 
                        "linkReport", "radioButton", "wrapperReferentRetailer", "wrapperReferentFinalCustomer", "formReferent", "sidebar", "mainContainer", "sidebarButton", 
                        "organization", "wrapperDashboard", "headerOrganization", "buttonUnsetSelectedOrganization", "containerButtonMultiple", "referentField", "labelOrganizationId", 
                        "inputRetailer","test", "inputReferent", "inputOrganization", "inputCustomer", "containerLogoCustomer", "userProjectsCounter", "customersLegend", "projectsLegend",
                        "usersLegend", "wrapperListOrganizations", "statisticsBlock", "blocTotalCounter", "listRange", "buttonRangeReport", "rangeReportContainer", "containerLogoOrganization"];
    
    /**
    * Sets up an event listener to handle the rendering of Turbo Frames and selects the appropriate user and customer cards based on URL parameters.
    */              
    connect() {
        this.element.addEventListener('turbo:frame-render', (event) => {
            const url = new URL(event.detail.fetchResponse.response.url)
            const params = new URLSearchParams(url.search);
            const selectedCustomer = params.get('selected_customer');
            const selectedUser = params.get('selected_user');
           
            if(selectedUser) {
                const userCard = Array.from(document.querySelectorAll(`.user-card`)).filter( card => card.id == selectedUser)[0];
                userCard ? userCard.classList.add('user-card--selected') : null;
            }
            
            if(selectedCustomer) {
                const customerCard = Array.from(document.querySelectorAll(`.customer-card`)).filter( card => card.id == selectedCustomer)[0];
                customerCard ? customerCard.classList.add('customer-card--selected') : null;
            }
        });
    }  

    //Organization method 


    /**
    * Sends an AJAX request to display the form for creating a new organization.
    */
    showNewOrganization = () => {
        Rails.ajax({
            type: "get",
            url: "organizations/new",
            success: (data) => {
                this.display(data, this.modaleNewDashboardTarget);
            },
            error: (data) =>console.log(data)
        });
    }

    /**
    * Appends a newly created Organization to the UI.
    *
    * This function is triggered by an event, typically related to the creation of a new project.
    * It updates the UI to display a confirmation message in `modaleNewDashboardTarget`,
    *
    * @param {Event} event - The event triggered by the user's action.
    * @param {*} event.detail - The details of the event.
    */
    appendOrganization = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.wrapperListOrganizationsTarget.innerHTML = xhr.response;

        this.displaySuccessMessage('Organisation', "Crée");
        
    }

    /**
     * Shows the edit form for an Organization.
     * 
     * This function is triggered by an event, retrieves the organization token from the event's
     * current target id, and makes an AJAX request to fetch the edit form for
     * the Organization. On success, it displays the form in the `modaleNewDashboardTarget`. On error,
     * it logs the error data to the console.
     *
     * @param {Event} event - The event triggered by the user's action.
     * @param {HTMLElement} event.currentTarget - The target element that triggered the event.
     * @param {string} event.currentTarget.id - The organization token from the id.
     */
    showEditOrganization = (event) => {
        const organizationToken = event.currentTarget.id;
        const page = window.location.href;

        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/edit`,
            data: new URLSearchParams({
                page: page
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => { console.log(data)}
        })
    }

    /**
   * Updates the organization information and displays a confirmation message.
   *
   * This function is triggered by an event, extracts the response data from the event details, calls 
   * the {@link getListOrganization} to fetch the updated list and the {@link getOrganizationHeader} to
   * display a the header of an Organization (in case of an organization had been selected previously).
   *
   * @param {CustomEvent} event - The custom event triggered by the user's action.
   * @param {*} event.detail - The details of the event.
   */
    updateOrganization = (event) => {
        const [_data, _status, xhr] = event.detail;
        const url = xhr.responseURL.split("/");
        const organizationToken = url[url.length-1];
        
        this.overlayTarget.style.display = "none";
        this.modaleNewDashboardTarget.style.display = "none";
        
        this.getListOrganizations();
        this.getOrganizationHeader(organizationToken);

        this.displaySuccessMessage('Organisation', 'modifiée')
    }

    /**
    * Fetch the list of organizations and remove any potential filter.
    */
    deleteOrganization = () => {
        this.getListOrganizations();
        this.undoOrganizationFilter();
    }

    /**
    * Resets the organization filter and refreshes the UI components related to users, customers, and projects.
    */
    undoOrganizationFilter = () => {
        this.getOrganizationHeader(null);
        this.getOrganizationsStatisticsBlock();
        this.getBlocTotalCounter();
        this.getBlocUsers(null, null);
        this.getBlocCustomers(null, null, null);
        this.getBlocProjects(null, null, null);
        this.handleCounter("users", this.usersCounterContainerTarget, null, null, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, null, null, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, null, null, null);

        document.querySelectorAll(".super-admin-sidebar__list-organizations__element").forEach((el) => {
            el.classList = "super-admin-sidebar__list-organizations__element";
        });
    }


    /**
    * Applies the organization filter based on the clicked element and updates the UI to display the related users, custoemrs and projects.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI.
    */
    launchOrganizationFilter(event) {
       
        this.displayLoaderOnGetRequest();
        const organizationToken = event.currentTarget.id;
        this.organizationTargets.forEach((organization) => {
            organization.classList.remove("super-admin-sidebar__list-organizations__element--activate");
        })
        event.currentTarget.classList.add("super-admin-sidebar__list-organizations__element--activate");

        this.getOrganizationHeader(organizationToken);
        this.getOrganizationStatisticsBlock(organizationToken);
        this.getOrganizationData(organizationToken);
        this.handleSidebar();
    }

    /**
    * Retrieves the list of organizations and updates the UI with the received data.
    */
    getListOrganizations = () => {
        Rails.ajax({
            type: 'get',
            url: '/organizations',
            success: (data) => {
                this.wrapperListOrganizationsTarget.innerHTML = data.html
            },
            error: (data) => console.log(data)
        })
    }

    /**
    * Retrieves and displays the header information for a specific organization.
    *
    * @param {string} organizationToken - The token of the organization for which the header information is to be retrieved.
    */
    getOrganizationHeader(organizationToken) {
        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/get_organization_header`,
            success: (data) => {
                this.headerOrganizationTarget.innerHTML = data.html;
            },
            error: (data) => { console.log(data) }
        })

        this.buttonUnsetSelectedOrganizationTarget.style.display = "block";
    }

    /**
    * Retrieves and displays the data related to a specific Organization to display the graphics.
    *
    * @param {string} organizationToken - The token of the organization for which the data informations has to be retrieved.
    */
    getOrganizationStatisticsBlock = (organizationToken) => {
        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/get_organization_statistics_block`,
            success: (data) => {
                this.statisticsBlockTarget.innerHTML = data.html;
                drawChart();
            },
            error: (data) => {console.log(data)}
        });
    }

    /**
    * Retrieves and displays the data related to a specific Organization to display the boards containing the counters of all related projects and draws.
    *
    * @param {string} organizationToken - The token of the organization for which the data informations has to be retrieved.
    */
    getOrganizationData = (organizationToken) => {
        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/get_organization_data`,
            success: (data) => {
                this.displaySection(data);
            },
            error: (data) => {console.log(data)}
        });
    }

    /**
    * Retrieves and displays the data related to a all Organizations to display the graphics.
    */
    getOrganizationsStatisticsBlock = () => {
        Rails.ajax({
            type: 'get',
            url: `/get_organizations_statistics_block`,
            success: (data) => {
                this.statisticsBlockTarget.innerHTML = data.html;
                drawChart();
            },
            error: (data) => {console.log(data)}
        });
    }

    /**
    * Retrieves and displays the data related to a all Organizations to display the total of each related projects and draws.
    */
    getBlocTotalCounter = () => {
        Rails.ajax({
            type: 'get',
            url: '/bloc_total_counter',
            success: (data) => {
                this.blocTotalCounterTarget.innerHTML = data.html
            },
            error: (data) => console.log(data)
        })
    }

    getBlocTotalCounterByOrganization = (organizationToken) => {
        Rails.ajax({
            type: "get",
            url: `/organizations/${organizationToken}/get_bloc_total_counter_by_organization`,
            success: (data) => {
                this.blocTotalCounterTarget.innerHTML = data.html
            },
            error: (data) => console.log(data)
        })
    }
  
    displaySection(data) {
        this.wrapperDashboardTarget.innerHTML = data.html;
        this.overlayTarget.style.display = "none";
    }



    //user method

    /**
    * Displays the form for signing up a new user within a specific organization.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI.
    */
    showNewUser = (event) => {
        const organizationToken = event.currentTarget.dataset.organizationToken;
        Rails.ajax({
            type: 'get',
            url: '/users/sign_up',
            data: new URLSearchParams({
                organization_token: organizationToken
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        });
    }


    /**
     * Shows the edit form for a user.
     * 
     * This function is triggered by an event, retrieves the user token from the event's
     * current target data attributes, and makes an AJAX request to fetch the edit form for
     * the user. On success, it displays the form in the `modaleNewDashboardTarget`. On error,
     * it logs the error data to the console.
     *
     * @param {Event} event - The event triggered by the user's action.
     * @param {HTMLElement} event.currentTarget - The target element that triggered the event.
     * @param {string} event.currentTarget.dataset.userToken - The user token from the data attribute.
     */
    editUser = (event) => {
        const token = event.currentTarget.dataset.userToken;

        Rails.ajax({
            type: 'get',
            url: `/admins/users/${token}/edit`,
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        });
    }

    /**
    * Appends a new user to the list and updates the UI components accordingly.
    */
    appendUser = () => {
        let organizationToken = null;

        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        if(selectedOrganization) {
            organizationToken = selectedOrganization.id;
        }

        this.getBlocUsers(organizationToken, null, null);
        this.handleCounter("users", this.usersCounterContainerTarget, organizationToken, null, null);

        this.displaySuccessMessage('Utilisateur', 'crée');
    }

    /**
    * Updates the user details based on the AJAX response and refreshes the user card UI.
    *
    * @param {Event} event - The event object containing details about the AJAX response.
    */
    updateUser = (event) => {
        const [_data, _status, xhr] = event.detail;
        const url = xhr.responseURL.split("/");
        let userToken = url[url.length-1];
        let selectedUser = false;
        if(document.querySelector('.user-card--selected') && userToken == document.querySelector('.user-card--selected').id ) {
            selectedUser = true;
        }
        this.getUserCard(userToken, selectedUser);

        this.displaySuccessMessage('Utilisateur', 'modifié');
    }

    /**
    * Deactivates a user by updating the user card with new HTML content from the AJAX response.
    *
    * @param {Event} event - The event object containing details about the AJAX response and the user card element.
    */
    desactivateUser = (event) => {
        const [_data, _status, xhr] = event.detail;
        const card = event.currentTarget.closest(event.params.typecard);
        card.outerHTML = _data.html || _data;
    }
    

    //project method

    /**
    * Displays the form for creating a new project within a specific customer.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI.
    */
    showNewProject = (event) => {
    const customerToken = event.currentTarget.dataset.customerToken;
    const organizationToken = event.currentTarget.dataset.organizationToken;
    if(customerToken) {
        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/projects/new`,
            data: new URLSearchParams({
                organization_token: organizationToken
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        });
    }
        else {
            this.disclaimerTarget.textContent = "Veuillez créer un premier client!"
            this.disclaimerTarget.setAttribute('style', "color: red")
        }    
    }

    /**
    * Sets up an event listener to fetch and display the list of customers for the selected organization.
    */
    handleCustomersList = () => {
        if(this.hasInputOrganizationTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                const organizationToken = event.currentTarget.value;
                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationToken}/customers_list`,
                    success: (data) => {
                        this.inputCustomerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
            })
        }
    }
    
    /**
    * Displays the form for reassigning a project to another user.
    *
    * @param {Event} event - The event object containing details about the user's interaction with the UI.
    */
    reassignProject = (event) => {
        const token = event.currentTarget.dataset.token;
       
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/reassign`,
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => console.log(data)
        });

        
    }

    /**
     * Updates the reassign project modal with a confirmation message and resets checkboxes.
     * 
     * This function updates the inner HTML of the `modaleNewDashboardTaget` with a confirmation
     * message. It then finds all checkboxes on the page and unchecks any that are checked.
     * It updates the users, customers and projects blocs regarding the update.
     */
    updateReassignProject = () => {
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const checkboxMultiple = document.getElementById('multipleCheck');
        const selectedUser = document.querySelector('.user-card--selected');
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let userToken = null;
        let customerToken = null;

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }
        
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id
        }
    
       
        if(selectedUser) {
            this.getUserCard(userToken, true);
        } else {
            this.getBlocUsers(organizationToken, userToken)
        }

        if(selectedCustomer) {
            this.getCustomerCard(userToken, customerToken, true);
        } else {
            this.getBlocCustomers(organizationToken, userToken, customerToken)
        }
        
        
        this.getBlocProjects(organizationToken, userToken, customerToken);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        
        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }

        this.displaySuccessMessage('Projet(s)', "réassigné(s)")
    }


    /**
    * Appends a newly created project to the UI and updates project counters.
    *
    * This function is triggered by an event, typically related to the creation of a new project.
    * It updates the UI to display a confirmation message in `mopdaleNewDshboardTarget`
    * and updates project counters.
    * It updates the projects bloc, the customers bloc ans the users bloc regarding the new project creation.
    *
    * @param {Event} event - The event triggered by the user's action.
    * @param {*} event.detail - The details of the event.
    * @param {Object} event.detail[0] - An object containing data related to the event.
    * @param {string} event.detail[0].html - The HTML content related to the newly created project.
    */
    appendProject = () => {
        const disclaimer = this.projectsContainerTarget.querySelector('.disclaimer-projects');
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        let customerToken = null;
        let userToken = null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        const selectedCustomer = document.querySelector(".customer-card--selected")
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        
        
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        this.getBlocProjects(organizationToken, userToken, customerToken);
        
        if(selectedCustomer == null) {
            this.getBlocCustomers(organizationToken, userToken, null);
        } else {
            this.getCustomerCard(null, customerToken, true);
        }

        this.getBlocUsers(organizationToken, userToken);
        organizationToken ? this.getBlocTotalCounterByOrganization(organizationToken) : this.getBlocTotalCounter();
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();

        this.displaySuccessMessage("Projet", 'créé');
    }

    /**
     * Deletes a project and updates the UI with a confirmation message.
     *
     * This function is triggered by an event and
     * updates the projects bloc, the customers bloc ans the users bloc to reflect the deletion of a project. It also displays a 
     * confirmation message in a modal and handles the necessary UI changes.
     *
     * @param {Event} event - The event triggered by the user's action.
     */
    deleteProject = (event) => {
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const selectedUser = document.querySelector('.user-card--selected');
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let userToken = null;
        let customerToken = null;

        
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        this.getBlocProjects(organizationToken, userToken, customerToken);
     
        if(userToken && customerToken) {
            this.getUserCard(userToken, true)
            this.getCustomerCard(userToken, customerToken, true);
        } else if(userToken) {
            this.getUserCard(userToken, true);
            this.getBlocCustomers(organizationToken, userToken, null)
        } else if(customerToken) {
            this.getBlocUsers(organizationToken, null, customerToken);
            this.getCustomerCard(userToken, customerToken, true);
        } else {
            this.getBlocUsers(organizationToken, null, null);
            this.getBlocCustomers(organizationToken, null, null);
        }

        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();
        
        this.displaySuccessMessage("Projet", 'supprimé');

        event.stopPropagation();
    }

    /**
    * Displays the form to reassign multiple projects previously selected to a specific user.
    */
    massReassign = () => {
        const projectTokens = [];

        document.querySelectorAll('input:checked').forEach((element) => {
            projectTokens.push(element.value)
        });
        Rails.ajax({
            type: 'get',
            url: "/projects/multiple_reassign_edit",
            data: new URLSearchParams({
                project_tokens: projectTokens
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        })
    }


    /**
     * Deletes multiple projects and updates the UI with a confirmation message.
     *
     * This function is triggered by an event and
     * updates the projects bloc, the customers bloc ans the users bloc to reflect the deletion of those projects. It also displays a 
     * confirmation message in a modal and handles the necessary UI changes.
     *
     * @param {Event} event - The event triggered by the user's action.
     */
    deleteMassProjects = (event) => {
        const checkboxMultiple = document.getElementById('multipleCheck');
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        let customerToken = null;
        let userToken = null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        const selectedCustomer = document.querySelector('.customer-card--selected');
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }
        
        if(userToken && customerToken) {
            this.getUserCard(userToken, true)
            this.getCustomerCard(userToken, customerToken, true);
        }
        else if(userToken) {
            this.getUserCard(userToken, true);
            this.getBlocCustomers(organizationToken, userToken, customerToken)
        } 
        else if(customerToken) {
            this.getBlocUsers(organizationToken, userToken, customerToken);
            this.getCustomerCard(userToken, customerToken, true);
        } else {
            this.getBlocUsers(organizationToken, userToken);
            this.getBlocCustomers(organizationToken, userToken, customerToken);
        }
        this.getBlocProjects(organizationToken, userToken, customerToken);
        this.handleCounter("projects", this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();
        

        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }

        this.containerButtonMultipleTarget.style.opacity = "0";
        this.containerButtonMultipleTarget.style.zIndex = "-1";
        

        this.displaySuccessMessage("Projets", "supprimés")

        event.stopPropagation();        
    }

    /**
    * Deactivates a project by updating its card and refreshes related UI components based on selected user and customer.
    *
    * @param {Event} event - The event object containing details about the AJAX response and the project card element.
    */
    desactivateProject = (event) => {
        const [_data, _status, xhr] = event.detail;
        const card = event.currentTarget.closest(event.params.typecard);
        let customerToken = null;
        let userToken = null;
        card.outerHTML = _data.html || _data;

        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }
        const selectedCustomer = document.querySelector('.customer-card--selected');
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
            this.getBlocCustomers(organizationToken, userToken, customerToken);
        } else {
            customerToken = selectedCustomer.id;
            this.getCustomerCard(userToken, customerToken, true);
        }
        
        event.stopPropagation();
    }

    

   //customer method

    /**
    * Displays the form for creating a new customer within a specific organization.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI.
    */
    showNewCustomer = (event) => {
        const organizationToken = event.currentTarget.dataset.organizationToken;

        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/customers/new`,
            success: ((data) => this.display(data, this.modaleNewDashboardTarget)),
            error: (data) => {console.log(data)}
        });
    }

    /**
     * Shows the edit form for a customer.
     * 
     * This function is triggered by an event, retrieves the customer token from the event's
     * current target data attributes, and makes an AJAX request to fetch the edit form for
     * the customer. On success, it displays the form in the `modaleNewDashboardTarget`. On error,
     * it logs the error data to the console.
     *
     * @param {Event} event - The event triggered by the user's action.
     * @param {HTMLElement} event.currentTarget - The target element that triggered the event.
     * @param {string} event.currentTarget.dataset.token - The customer token from the data attribute.
     */
    editCustomer = (event) => {
        const token = event.currentTarget.dataset.token;

        Rails.ajax({
            type: 'get',
            url: `/customers/${token}/edit`,
            data: new URLSearchParams({
                page: "dashboard"
            }),
            success: ((data) => this.display(data, this.modaleNewDashboardTarget)),
            error: (data) => { console.log(data) }
        })
    }

    /**
    * Handles error responses by displaying a modal with error details and setting up relevant UI components.
    *
    * @param {Event} event - The event object containing details about the AJAX response and the error.
    */
    errorNew(event) {
        this.modaleNewDashboardTarget.setAttribute('style', 'display: flex');
        const [_data, _status, xhr] = event.detail;
        this.modaleNewDashboardTarget.innerHTML = xhr.response; 
    
        this.handleCloseButton();
        openCollapse();
        
          if(this.hasLabelOrganizationIdTarget) {
            const inputOrganization = document.getElementById('inputOrganization');
            if(this.labelOrganizationIdTarget.closest('form').dataset.container == "form-new-customer" || this.labelOrganizationIdTarget.closest('form').dataset.container == "form-edit-customer") {
                 this.handleCanalCustomer();
                 this.handleReferentsList();

                 if(inputOrganization){
                    this.handleRetailersList();
                 }
            }
            else {
                this.handleCustomersList();
            }
        }
    }

    /**
    * Appends a newly created customer to the UI and updates customer counters, the customer's bloc and the project's bloc.
    *
    * This function is triggered by an event, typically related to the creation of a new customer.
    * It updates the UI to display a confirmation message in `mopdaleNewDshboardTarget`.
    */
    appendCustomer = () => {
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const selectedCustomer = null;

       
        this.getBlocCustomers(organizationToken, null, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken, null, selectedCustomer);
        this.getBlocProjects(organizationToken, null, selectedCustomer);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, selectedCustomer);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();

        

        const disclaimer = this.customersContainerTarget.querySelector('.disclaimer-projects');
        if (disclaimer) {
            disclaimer.remove();
        }

       this.displaySuccessMessage('Client', 'créé');
    }

    /**
    * Updates the customer details based on the AJAX response and refreshes the customer card UI.
    * Depending on the context, it also updates the project's bloc. 
    *
    * @param {Event} event - The event object containing details about the AJAX response.
    */
    updateCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const url = xhr.responseURL.split("/");
        let userToken = null;
        let customerToken = url[url.length-1];
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const userCardSelected = document.querySelector('.user-card--selected');
        const customerCardUpdated = document.getElementById(`${customerToken}`);
        const customerUpdatedIsSelected = customerCardUpdated.classList.contains('customer-card--selected');
        const otherCardIsSelected = Array.from(document.querySelectorAll('.customer-card')).filter( card => card.classList.contains('customer-card--selected'));

        if (!userCardSelected) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = userCardSelected.id;
        }
        
        this.getCustomerCard(userToken, customerToken, customerUpdatedIsSelected);
        
        if (otherCardIsSelected.length > 0 && otherCardIsSelected[0].id != customerToken) {
            const otherCardSelected = otherCardIsSelected[0];
            if(userCardSelected) {
                this.getBlocProjects(organizationToken, userToken, otherCardSelected.id);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, otherCardSelected.id);
            } else {
                this.getBlocProjects(organizationToken, null, otherCardSelected.id);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, otherCardSelected.id);
            }
        } else  {
            if(userCardSelected && customerUpdatedIsSelected ) {
                this.getBlocProjects(organizationToken, userToken, customerToken),
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken)
            } 
            else if (userCardSelected){
                this.getBlocProjects(organizationToken, userToken, null);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, null);
            }
            else if (customerUpdatedIsSelected) {
                document.getElementById(`${customerToken}`).classList.add('customer-card--selected');
                this.getBlocProjects( organizationToken,null, customerToken);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, customerToken);
            } else {
                this.getBlocProjects(organizationToken, null, null);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, null);
            }
            
        }

        this.displaySuccessMessage('Client', "modifié");
    }

    /** 
     * Updates the UI of the customer card to desactivate it by sending AJAX request. 
     * It also updates the project's bloc by desactivating projects related to the customer.
     * 
     * @param {Event} event - The event object containing details about the AJAX response.
    */
    desactivateCustomer = (event) => {
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const customer = event.currentTarget.closest('.customer-card');
        let customerToken = customer.id;
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let filteredCustomerToken = null;
        const selectedUser = document.querySelector('.user-card--selected');
        let filteredUserToken = null;

        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer.id;
        }

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? filteredUserToken = userParams.get('selected_user') : null;
        } else {
            filteredUserToken = selectedUser.id;
        }

        this.displayLoaderOnGetRequest();
        
        Rails.ajax({
            type: 'patch',
            url: `/customers/${customerToken}/desactivate_related_project`,
            data: new URLSearchParams({
                filtered_customer_token: filteredCustomerToken,
                filtered_user_token: filteredUserToken
            }), 
            success: () => {
                this.removeLoader();
                this.getCustomerCard(filteredUserToken, customerToken, customerToken == filteredCustomerToken);
                this.getBlocProjects(selectedOrganization, filteredUserToken, filteredCustomerToken)
            },
            error: (data) => console.log(data)
        });
    }

    //Filter method

    //Filter on User

    /**
    * Applies a filter to show user-specific data and updates the UI accordingly.
    * It updates the project's bloc and the customer's bloc.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI, including the user token.
    */
    launchUserFilter = (event) => {
        this.resetSelectedUser();
        
        let organizationToken = null;
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");

        if(selectedOrganization) {
            organizationToken = selectedOrganization.id
        }
        const userToken = event.currentTarget.dataset.userToken;
        event.currentTarget.parentNode.classList.add('user-card--selected');

        this.getBlocCustomers(organizationToken, userToken, null);
        this.getBlocProjects(organizationToken, userToken, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken,  userToken, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken,  userToken, null);
        this.displayResetFilterUser();
    }

    //Filter on customer

    /**
    * It calls {@link customerFilter} to updates the project's bloc with data related to the selected customer.
    * It also updates the counter of project's related to the customer by calling {@link getCountSelectedCustomerProjects}.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI, including the cusdtomer token.
    */
    launchCustomerFilter = (event) => {
        this.displayLoaderOnGetRequest();
        this.customerFilter(event);
        this.getCountSelectedCustomerProjects(event);
        this.displayResetFilterCustomer();
    }

    /**
    * Applies a filter to show customer-specific data and updates the UI accordingly.
    * It updates the project's bloc.
    *
    * @param {Event} event - The event object representing the user's interaction with the UI, including the customer token.
    */
    customerFilter = (event) => {
        this.resetSelectedCustomer();
        const selectedUser = document.querySelector(".user-card--selected");
        const customerToken = event.currentTarget.dataset.customerToken;
        let userToken = null;

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        if (selectedUser) {
    
            event.currentTarget.parentNode.classList.add('customer-card--selected');
    
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/filter_projects_by_user_and_customer`,
                data: new URLSearchParams({
                    user_token: userToken
                }),
                success: (data) => {
                    this.displayFilter(data, this.projectsContainerTarget);
                    this.removeSortItem(this.projectsLegendTarget);
                    this.removeLoader();
                },
                error: (data) => { console.log(data) }
            })
        }

        else {

            event.currentTarget.parentNode.classList.add('customer-card--selected');

            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/filter_projects_by_customer`,
                success: (data) => { 
                    this.displayFilter(data, this.projectsContainerTarget);
                    this.removeSortItem(this.projectsLegendTarget);
                    this.removeLoader();
                },
                error: (data) => { console.log(data) }
            });
        }
    }

    //Reset method

    /**
    * Resets the selected user by removing the 'selected' class from all user cards.
    */
    resetSelectedUser = () => {
        const userCards = document.querySelectorAll(".user-card");
        userCards.forEach((card) => {
            card.classList.remove('user-card--selected');
        })
    }

    /**
    * Resets the selected customer by removing the 'selected' class from all customer cards.
    */
    resetSelectedCustomer= () => {
        
        const customerCards = document.querySelectorAll(".customer-card");
        customerCards.forEach((card) => {
            card.classList.remove('customer-card--selected');
        })
    }



    displayResetFilterUser() {
        this.resetFilterButtonUserTarget.style.opacity = "1";
        this.resetFilterButtonUserTarget.style.zIndex = "0";

    }

    removeResetFilterUser() {
        this.resetFilterButtonUserTarget.style.opacity = "0";
        this.resetFilterButtonUserTarget.style.zIndex = "-1";
    }

    displayResetFilterCustomer() {
        this.resetFilterButtonCustomerTarget.style.opacity = "1";
        this.resetFilterButtonCustomerTarget.style.zIndex = "0";

    }

    removeResetFilterCustomer() {
        this.resetFilterButtonCustomerTarget.style.opacity = "0";
        this.resetFilterButtonCustomerTarget.style.zIndex = "-1";
    }

    /**
    * Resets the user filters to display all users, customers, and projects based on the selected organization and customer.
    *
    * @param {Event} event - The event object representing the user's interaction, used to stop event propagation.
    */
    undoUsersFilter = (event) => {
        let organizationToken = null;
        let customerToken = null;

        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        if (selectedOrganization) {
            organizationToken = selectedOrganization.id;
        }
        
        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        this.handleCounter('users', this.usersCounterContainerTarget, organizationToken,  null, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken, null, customerToken);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, customerToken);
        this.getBlocUsers(organizationToken, null);
        this.getBlocCustomers(organizationToken, null, customerToken);
        this.getBlocProjects(organizationToken, null, customerToken);
        this.removeSortItem(this.customersLegendTarget);
        this.removeSortItem(this.projectsLegendTarget);
   
        event.stopPropagation();
    }

    /**
    * Resets the customer filters to display all users, customers, and projects based on the selected organization and user.
    *
    * @param {Event} event - The event object representing the user's interaction, used to stop event propagation.
    */
    undoCustomersFilter = (event) => {
        let organizationToken = null;
        let userToken = null;
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        const selectedUser = document.querySelector(".user-card--selected");

        if (selectedOrganization) {
            organizationToken = selectedOrganization.id;
        }

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }
    
        this.getBlocCustomers(organizationToken, userToken, null);
        this.getBlocProjects(organizationToken, userToken, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken, userToken, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, null);
        this.removeSortItem(this.customersLegendTarget);
        this.removeSortItem(this.projectsLegendTarget);
        event.stopPropagation();
        
    }


    //Count filter method

    /**
    * Retrieves the count of customers associated with a specific user and updates the UI accordingly.
    *
    * @param {Event} event - The event object triggered by the user's action, containing the user token.
    */
    getCountCustomersByUser = (event) => {
        const userToken = event.currentTarget.dataset.userToken;
        Rails.ajax({
            type: 'get',
            url: `/admins/users/${userToken}/counter_customers_by_user`,
            success:  (data) => {
                this.displayCounter(data, this.customersCounterContainerTarget);
                this.addCustomerTarget.setAttribute('style', 'display: none');
            },
            error: (data) => { console.log(data) }
        })
    }

    /**
    * Retrieves the count of projects associated with a selected customer and user, and updates the UI accordingly.
    *
    * @param {Event} event - The event object triggered by the user's action, containing the customer token.
    */
    getCountSelectedCustomerProjects = (event) => {
        const selectedUser = document.querySelector(".user-card--selected");
        const customerToken = event.currentTarget.dataset.customerToken;
        let userToken = null;

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        if (selectedUser) {
    
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/counter_projects_by_user_and_customer`,
                data: new URLSearchParams({
                    user_token: userToken
                }),
                success: (data) => { 
                    this.displayFilter(data, this.projectsCounterContainerTarget); 
                    this.addProjectTarget.setAttribute('style', 'display: none');
                },
                error: (data) => { console.log(data) }
            })
        }

        else {
            
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/counter_projects_by_customer/`,
                success: (data) => { this.displayFilter(data, this.projectsCounterContainerTarget) },
                error: (data) => { console.log(data) }
            })
        }
    } 

    //display method

    /**
    * Displays the provided data in a specified container and handles various form configurations.
    *
    * @param {Object|string} data - The data to display. Can be an object containing HTML or a simple string.
    * @param {HTMLElement} container - The HTML element where the data will be displayed.
    */
    display(data, container) {
        this.overlayTarget.setAttribute('style', 'display: flex');
        container.setAttribute('style', 'display: flex');
        if(data.html) {
            container.innerHTML = data.html;
        }
        else {
            container.innerHTML = data;
        }
        

        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            container.setAttribute('style','display: none');
        })

        this.handleCloseButton();
        const form = container.querySelector('form').dataset.container;

        if(form) {
            switch (form) {
                case "form-new-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleCanalCustomer();
                    this.handleRetailersList();
                    this.handleReferentsList();
                    this.checkIfRetailersExist();
                    break;
                case "form-edit-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleRetailersList();
                    this.handleReferentsList();
                    break;
                case "form-reassign":
                case "form-custom-date":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                    break;
                case "form-new-organization":
                case "form-edit-organization":
                case "form-mass-reassign":
                case "form-edit-user":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    break;
                case "form-new-project":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleCustomersList();
                    break;
              /*   case "form-edit-project":
                    container.classList = "modale-new";
                    this.handleCustomersList();
                    break; */
                default:
                    container.classList = "modale-new";
            }
        }
       
    }

    displayFilter(data, container) {
        container.innerHTML = data.html;
    }

    displayCounter(data, container) {
        container.innerHTML = data.html;
    }

    /**
    * Displays an error message in a modal when an AJAX request fails.
    *
    * @param {Event} event - The event object containing details about the failed AJAX request.
    */
    displayError(event) {
        const [_data, _status, xhr ] = event.detail;
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleNewDashboardTarget.setAttribute('style', 'display: flex');
        this.modaleNewDashboardTarget.classList = 'modale-new modale-new--x-small';
        this.modaleNewDashboardTarget.innerHTML = xhr.response;
        this.handleCloseButton();
    }

    //Form method

    /**
    * Toggles the display of a referent form and adjusts the arrow icon's orientation.
    *
    * @param {Event} event - The event object triggered by the user interaction.
    */
    displayReferentForm(event) {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");
        const form = document.querySelector('.form-new');
      
       
        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            form.scrollTo({top: form.offsetHeight, behavior: 'smooth'})

        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }
    }

    //delete method

    /**
    * Deletes a customer and updates related UI elements.
    *
    * @param {Event} event - The event object triggered when a customer is deleted.
    */
    deleteCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const customerUrl = xhr.responseURL.split("/");
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        let customerToken = customerUrl[customerUrl.length-1];
        let userToken = null;
        let filteredCustomerToken = null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer.id;
        }

        if(userToken) {
            this.getUserCard(userToken, true);
        } else {
            this.getBlocUsers(organizationToken, userToken);
        }

        this.getBlocCustomers(organizationToken, userToken, filteredCustomerToken);
        this.getBlocProjects(organizationToken, userToken, filteredCustomerToken != customerToken ? filteredCustomerToken : null);
 
        this.handleCounter("customers", this.customersCounterContainerTarget,organizationToken, userToken, filteredCustomerToken);
        this.handleCounter("projects", this.projectsCounterContainerTarget,organizationToken, userToken, filteredCustomerToken != customerToken ? filteredCustomerToken : null);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();

        if(this.resetFilterButtonCustomerTarget.style.opacity == "1") {
            this.resetFilterButtonCustomerTarget.style.opacity = "0";
        }

      
       this.displaySuccessMessage("Client", "supprimé")
     
    
    }


    // API calls
    /**
    * Retrieves and displays a filtered list of users.
    *
    * @param {string|null} organizationToken - The token of the selected organization (if any).
    * @param {string|null} userToken - The token of the selected user (if any).
    */
    getBlocUsers = (organizationToken, userToken) => {

        Rails.ajax({
            type: 'get',
            url: `/admins/users`,
            data: new URLSearchParams({
                selected_user: userToken,
                selected_organization: organizationToken
            }),
            success: (data) => {
                this.usersContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.usersLegendTarget);
                if(userToken && document.getElementById(`${userToken}`)) {
                    document.getElementById(`${userToken}`).classList.add('user-card--selected');
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    /**
    * Retrieves and updates a specific user card's HTML.
    *
    * @param {string} userToken - The token of the user whose card is to be retrieved.
    * @param {boolean} [selected=false] - Whether to mark the user card as selected.
    */
    getUserCard = (userToken, selected = false) => {
        Rails.ajax({
            type: 'get',
            url: `/admins/users/${userToken}/get_user_card`,
            success: (data) => {
                document.getElementById(`${userToken}`).outerHTML = data.html;
                selected ? document.getElementById(`${userToken}`).classList.add('user-card--selected') : null;
                
            },
            error: (error) => { console.log(error) }
        });
    }

    /**
    * Fetches and displays customer data for a specific organization, user, and customer.
    *
    * This function sends an AJAX GET request to the `/customers` endpoint, passing the organization,
    * user, and customer tokens as query parameters. It processes the server response to update the UI
    * with customer data, handle various states, and manage visibility of certain elements based on
    * the provided tokens.
    *
    * @param {string} organizationToken - The token representing the selected organization.
    * @param {string} userToken - The token representing the selected user.
    * @param {string} customerToken - The token representing the selected customer.
    */
    getBlocCustomers = (organizationToken, userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/customers`,
            data: new URLSearchParams({
                selected_organization: organizationToken,
                selected_user: userToken,
                selected_customer: customerToken
            }),
            success: (data) => {
               
                this.customersContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.customersLegendTarget);

                if(!this.addProjectTarget.dataset.customerToken) {
                    this.addProjectTarget.customerToken = document.querySelector(".customer-card").id 
                }
                
                if(userToken && document.getElementById(`${userToken}`) ) {
                    document.getElementById(`${userToken}`).classList.add('user-card--selected');
                }
                if(customerToken && document.getElementById(`${customerToken}`)) {
                    document.getElementById(`${customerToken}`).classList.add('customer-card--selected');
                }

                if(this.customersContainerTarget.querySelector(".customer-card") == null) {
                    this.customersContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de clients existant</p>";
                    this.addProjectTarget.id = '';
                }

                if(userToken) {
                    this.addCustomerTarget.setAttribute('style', 'display: none');
                }
               
                if(customerToken) {
                    this.resetFilterButtonCustomerTarget.style.opacity = "1";
                    this.resetFilterButtonCustomerTarget.style.zIndex = "0";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    /**
    * Fetches and updates the customer card HTML for a specific customer and user.
    *
    * This function sends an AJAX GET request to retrieve the HTML for a customer card,
    * identified by the given `customerToken`, and updates the existing customer card in
    * the DOM. It optionally applies a selected state to the customer card based on the `selected` parameter.
    *
    * @param {string} userToken - The token representing the selected user.
    * @param {string} customerToken - The token representing the selected customer.
    * @param {boolean} selected - Whether the customer card should be marked as selected.
    */
    getCustomerCard = (userToken, customerToken, selected) => {
        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/get_customer_card`,
            data: new URLSearchParams({
                selected_user: userToken
            }),
            success: (data) => {
                document.getElementById(`${customerToken}`).outerHTML = data.html;
                selected ? document.getElementById(`${customerToken}`).classList.add(('customer-card--selected')) : null ;
                
            },
            error: (error) => { console.log(error) } 
        });
    }

    /**
    * Fetches and displays project data for a specific organization, user, and customer.
    *
    * This function sends an AJAX GET request to the `/projects` endpoint, passing the organization,
    * user, and customer tokens as query parameters. It processes the server response to update the UI
    * with project data, manages visibility of the "Add Project" and "Add Customer" elements, and 
    * displays a message if no projects are found.
    *
    * @param {string} organizationToken - The token representing the selected organization.
    * @param {string} userToken - The token representing the selected user.
    * @param {string} customerToken - The token representing the selected customer.
    */
    getBlocProjects = (organizationToken, userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/projects`,
            data: new URLSearchParams({
                organization_token: organizationToken,
                user_token: userToken,
                customer_token: customerToken
            }),
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.projectsLegendTarget);

                if(userToken) {
                    this.addProjectTarget.setAttribute('style', 'display: none');
                    this.addCustomerTarget.setAttribute('style', 'display: none');
                }

                if(this.projectsContainerTarget.querySelector(".project-card") == null) {
                    this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de projet existant</p>";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    //utils

    handleCheckbox(event) {
        handleCheckbox(event);
    }

    /**
    * Toggles the display of the range report options.
    *
    * This function checks if the range report list is currently displayed by checking for a specific CSS class.
    * If the list is displayed, it hides the list and resets the button style. 
    * If the list is not displayed, it shows the list and updates the button style to indicate the active state.
    */
    displayRangeReportChoice() {
        if (this.listRangeTarget.classList.contains("list-range--displayed")) {
            this.buttonRangeReportTarget.classList.remove('button-report--toggle')
            this.listRangeTarget.classList.remove("list-range--displayed");
            
        } else {
            this.buttonRangeReportTarget.classList.add('button-report--toggle');
            this.listRangeTarget.classList.add("list-range--displayed");
        }
    }

    /**
    * Filters data based on a selected date range and updates the UI accordingly.
    *
    * This function handles a date range selection event, updating the display of the selected range and
    * hiding/showing the appropriate range options. It sends an AJAX GET request to filter data based on the 
    * selected date range and updates the report download link dynamically with the new date parameters.
    *
    * @param {Event} event - The event triggered by selecting a new date range.
    */
    launchFilterDate(event) {
        const newRange = event.currentTarget;
        const currentRange = this.rangeReportContainerTarget.innerHTML;
        this.rangeReportContainerTarget.innerHTML = newRange.innerHTML;
        this.rangeDateTargets.find( el => el.innerHTML == newRange.innerHTML ).classList.add("range-element--hidden");
        this.rangeDateTargets.find( el => el.innerHTML == currentRange ).classList.remove("range-element--hidden");
       
        
        Rails.ajax({
            type: 'get',
            url: `/filter_by_date/${newRange.dataset.range}`,
            success: (data) =>  {
                let array = this.linkReportTarget.href.split("/").slice(3);
                array.splice(-2, 1, `${data[0]}`);
                array.splice(-1, 1, `${data[1]}`);
                array = array.join("/");
                const url = "/" + array + ".xlsx";
                this.linkReportTarget.href = url;
                this.buttonRangeReportTarget.classList.remove('button-report--toggle')
                this.listRangeTarget.classList.remove("list-range--displayed");
            },
            error: (data) => {console.log(data)}
        }) 
    }

    /**
    * Initiates a custom date filter request and displays the result.
    *
    * This function sends an AJAX GET request to the `/custom_date` endpoint to apply a custom date filter.
    * Upon successful response, it displays the returned data in the specified modal element.
    *
    */
    launchCustomFilterDate() {
      Rails.ajax({
          type: 'get',
          url: '/custom_date',
          success: ((data) => this.display(data, this.modaleNewDashboardTarget)),
          error: (data) => console.log(data)
      });
    }

    /**
    * Handles the display logic based on the selected customer channel.
    *
    * This function attaches change event listeners to all radio input elements. When a radio button is selected,
    * it checks the value of the selected option. If the value is "revendeur", it hides the final customer referent
    * wrapper and displays the retailer referent wrapper. If the value is not "revendeur", it performs the opposite,
    * hiding the retailer referent wrapper and displaying the final customer referent wrapper.
    */
    handleCanalCustomer() {
        document.querySelectorAll("input[type=radio]").forEach((radio) => {
            radio.addEventListener("change", (event) => {
                if(event.currentTarget.value == "revendeur") {
                    this.wrapperReferentFinalCustomerTarget.setAttribute('style', 'display: none');
                    this.wrapperReferentRetailerTarget.setAttribute('style', 'display: block');
                }
                else {
                    this.wrapperReferentRetailerTarget.setAttribute('style', 'display: none');
                    this.wrapperReferentFinalCustomerTarget.setAttribute('style', 'display: block');
                }
                
            });
        });
    }

    /**
    * Handles the dynamic update of retailers and referents based on the selected organization.
    *
    * This function checks if both the organization and retailer input elements are present.
    * When the organization input changes, it sends two AJAX GET requests:
    * - The first request fetches the list of retailers for the selected organization and updates the retailer input element.
    * - The second request fetches the list of referents for the (initially null) retailer under the selected organization and updates the referent input element.
    */
    handleRetailersList = () => {
        if(this.hasInputOrganizationTarget && this.hasInputRetailerTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                
                const organizationToken = event.currentTarget.value;

                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationToken}/retailers_list`,
                    success: (data) => {
                 
                        this.inputRetailerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
               
                const retailerId = null
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    data: new URLSearchParams({
                        organization_token: organizationToken
                    }),
                    success: (data) => {
                        
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                }); 
            })
        }
    }

    /**
    * Handles the dynamic update of referents based on the selected retailer.
    *
    * This function checks if the retailer input element is present. When the retailer input changes,
    * it sends an AJAX GET request to fetch the list of referents associated with the selected retailer
    * and updates the referent input element with the retrieved data. It also ensures that the referent
    * field is made visible by removing the 'hidden' class.
    */
    handleReferentsList = () => {
        if(this.hasInputRetailerTarget) {
            this.inputRetailerTarget.addEventListener("change", (event) => {
                const retailerId = event.currentTarget.value;
                this.referentFieldTarget.classList.remove('hidden')
                
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    success: (data) => {
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                });
            });
        }
    } 

    /**
    * Handles the dynamic update of referents list during the edit process based on the selected retailer.
    *
    * This function sets up an event listener on the retailer input element to detect changes.
    * When the retailer selection changes, it sends an AJAX GET request to fetch the list of referents
    * associated with the selected retailer and updates the referent input element with the retrieved data.
    * Additionally, it ensures that the referent field is made visible by removing the 'hidden' class.
    *
    */
    handleEditReferentsList = () => {
        this.inputRetailerTarget.addEventListener("change", (event) => {
            const retailerId = event.currentTarget.value;
            this.referentFieldTarget.classList.remove('hidden')
            
            Rails.ajax({
                type: 'get',
                url: `/retailers/${retailerId}/referents_list`,
                success: (data) => {
                    this.inputReferentTarget.innerHTML = data.html;
                },
                error: (data) => {console.log(data)}
            });
        });
    }


    handleSidebar() {
        if(this.sidebarTarget.style.width == "20%") {
            this.sidebarTarget.setAttribute('style', 'width: 0');
            this.mainContainerTarget.setAttribute('style', 'width: 100%');
           
            this.sidebarButtonTarget.style.display = "block";
            this.organizationTargets.forEach((organization) => {
                organization.setAttribute('style', 'display: none');
            })
        }
        else {
            this.sidebarTarget.setAttribute('style', 'width: 20%');
            this.mainContainerTarget.setAttribute('style', 'width: 80%');
            
            this.sidebarButtonTarget.style.display = "none";
            this.organizationTargets.forEach((organization) => {
                organization.setAttribute('style', 'display: block');
            })
        }
        
    }


    /**
    * Sorts the items when a sortable column is clicked.
    * This function triggers an AJAX request to sort items based on the clicked column,
    * and updates the UI accordingly, handling selected organizations, users, and customers.
    *
    * @param {Event} event - The event object from the click event on a sortable column.
    */
    sortItems = (event) => {
        this.displayLoaderOnGetRequest();
        const item = event.currentTarget.dataset.item;
        const column = event.currentTarget.dataset.column;
        const arrow = event.currentTarget.querySelector("i");
        const container = document.querySelector(`.${item}s-cards-container`);
        let cssClass = "rotate--visible";
       
        //Check for selected Organization
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        ;

        //check for selected User
        let userToken = null;
        const selectedUser = document.querySelector(".user-card--selected");
        const userParams = getUrlParams('users-page-2');
       
        if (!selectedUser) {
            userParams ? userToken = userParams.get('selected_user') : null
        } else {
            userToken = selectedUser.id;
        }


        //Check for selected Customer
        let customerToken = null;
        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2');
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        //Get selected cards for display
        const filteredItems = Array.from(document.querySelectorAll(`.${item}-card`)).filter( card => card.classList.contains(`${item}-card--selected`));
        const blocIndex = event.currentTarget.parentNode;
        const sortableItems = Array.from(blocIndex.querySelectorAll(".sortable"));
        const itemNotSelected = sortableItems.filter(element => element != event.currentTarget);
        

        itemNotSelected.forEach((element) => {
            if(element.querySelector("i").classList.contains("rotate--visible") || element.querySelector("i").classList.contains("rotate--180")) {
                sortableItems.forEach(element => element.querySelector("i").classList = "fas fa-arrow-down rotate")
            }
        });

        if(event.currentTarget.querySelector("i").classList.contains("rotate--visible")) {
             cssClass = "rotate--180";
        }
        else if(event.currentTarget.querySelector("i").classList.contains("rotate--180")) {
            cssClass = "";
        }
        
        Rails.ajax({
            type: 'get',
            url: `/launch_sort_${item}s`,
            data: new URLSearchParams({
                item: item,
                column: column,
                css_class: cssClass,
                selected_organization: selectedOrganization,
                user_token : userToken,
                customer_token: customerToken
            }),
            success:(data) => {
                this.removeLoader();
                container.innerHTML = data.html;
                arrow.classList = `fas fa-arrow-down rotate ${cssClass}`;
               
                if(userToken || customerToken) {
                    if(userToken) {
                        const userCard = document.getElementById(userToken);
                        if(userCard) {
                            userCard.classList.contains('user-card--selected') ? null : userCard.classList.add(`user-card--selected`)
                        }
                    }

                    if(customerToken) {
                        const customerCard = document.getElementById(customerToken);
                        if(customerCard) {
                            customerCard.classList.contains('customer-card--selected') ? null : customerCard.classList.add(`customer-card--selected`)
                        }
                    }
                } 
             },
            error: (data) => { console.log(data) }
        })
    }

    /**
    * Resets the sorting arrows in a given block by removing any custom rotation classes.
    *
    * @param {HTMLElement} bloc - The DOM element containing sortable items with rotate icons.
    */
    removeSortItem = (bloc) => {
        const items = bloc.querySelectorAll(".rotate");
        items.forEach( item => item.classList = "fas fa-arrow-down rotate")
    }

    //tools

    /**
    * Handles the close button functionality by adding a click event listener.
    * When the close button is clicked, the modal and overlay are hidden.
    */
    handleCloseButton() {
        this.buttonCloseTarget.addEventListener("click", () => {
            this.modaleNewDashboardTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
        
    }

    /**
    * Sends an AJAX request to refresh the project counter.
    * The function retrieves the updated project count and updates the respective container.
    */
    refreshProjectsCounter = () => {
        Rails.ajax({
            type: 'get',
            url: '/get_counter_projects',
            success: (data) => {
                this.projectsCounterContainerTarget.innerHTML = data.html;
            },
            error: (data) => console.log(data)
        });
    }

    /**
    * Handles updating various counters (users, customers, projects) based on the selected organization, user, or customer.
    * It sends an AJAX request for the specified counter type and updates the corresponding counter container.
    *
    * @param {string} type - The type of counter to update ('users', 'customers', 'projects').
    * @param {HTMLElement} counterContainer - The DOM element where the counter will be updated.
    * @param {string|null} selectedOrganization - The ID of the selected organization (or null if none).
    * @param {string|null} selectedUser - The ID of the selected user (or null if none).
    * @param {string|null} selectedCustomer - The ID of the selected customer (or null if none).
    */
    handleCounter = (type, counterContainer, selectedOrganization, selectedUser, selectedCustomer) => {
        switch (type) {
            case 'users':
                Rails.ajax({
                    type: 'get',
                    url: '/admins/users/counter_users',
                    data: new URLSearchParams({
                        selected_organization: selectedOrganization,
                    }),
                    success: (data) => {
                        counterContainer.outerHTML = data.html
                    }
                });
                break;
            case 'customers':
                Rails.ajax({
                    type: 'get',
                    url: '/customers/counter_customers',
                    data: new URLSearchParams({
                        selected_organization: selectedOrganization,
                        selected_user: selectedUser,
                }),
                    success: (data) => {
                        counterContainer.innerHTML = data.html;

                        if(selectedUser) {
                            this.addCustomerTarget.setAttribute('style', 'display: none');
                        }
                    }
                });
                break;
            case 'projects':
            Rails.ajax({
                type: 'get',
                url: '/projects/get_counter_projects',
                data: new URLSearchParams({
                    selected_organization: selectedOrganization,
                    selected_user: selectedUser,
                    selected_customer: selectedCustomer,  
                }),
                success: (data) => {
                    counterContainer.innerHTML = data.html;

                    if(selectedUser) {
                        this.addProjectTarget.setAttribute('style', 'display: none');
                    }
                }
            });
            break;
            default: null
        } 

    }

    /**
    * Displays the loader on a POST request, handling errors if any are found.
    * If there are errors related to Dropzone, it will call a function to control them.
    * Otherwise, it hides the modal.
    *
    * @param {Event} event - The event object triggered by the form submission or button click.
    */
    displayLoaderOnPostRequest(event) {
        const errors = document.querySelectorAll('.dz-error');

        if(errors.length > 0) {
            this.controlDropzoneErrors(event);
        } else {
            this.modaleNewDashboardTarget.setAttribute('style', 'display: none');
        }
    }

    /**
    * Displays a loading overlay when a GET request is initiated.
    * It sets the overlay element's display style to 'block', making it visible.
    */
    displayLoaderOnGetRequest() {
        this.overlayTarget.setAttribute('style', 'display: block');
    }

    /** 
    * Removes the overlay element to hide the loader.
    */
    removeLoader() {
        this.overlayTarget.style.display = "none";
    } 

    /**
    * Checks if retailers exist when the organization is changed.
    * Listens for a change event on the organization input, then sends a GET request
    * to retrieve the relevant customers and displays them in the modal.
    */
    checkIfRetailersExist = () => {
        this.inputOrganizationTarget.addEventListener('change', () => {
            const organizationId = this.inputOrganizationTarget.value;

            Rails.ajax({
                type :'get',
                url: `/organizations/${organizationId}/customers/new`,
                success: (data) => this.display(data, this.modaleNewDashboardTarget),
                error: (data) => console.log(data)
            })
        })
    }

    /**
    * Controls and handles Dropzone errors on form submission.
    * If there are any Dropzone errors, it prevents form submission, displays an error message,
    * and scrolls to the first error field.
    *
    * @param {Event} event - The event object from form submission.
    */
    controlDropzoneErrors(event) {
        const errors = document.querySelectorAll('.dz-error');

        if(errors.length > 0) {
            event.preventDefault();
            const errorMessage = `<p class="error-plan-weight">Veuillez vérifier ce champ</p>`;
            const customerLogoContainers = this.containerLogoCustomerTargets;
            const organizationLogoContainers = this.containerLogoOrganizationTargets;
            const containers = [customerLogoContainers, organizationLogoContainers]

            containers.forEach((container) => {
                container.forEach((el) => {
                    if(el.querySelector('.dz-error')) {
                        const containerMessage = el.parentElement.querySelector('label');

                        if(containerMessage.querySelector(".error-plan-weight") == null) {
                            containerMessage.insertAdjacentHTML('beforeend', errorMessage);
                            containerMessage.querySelector('.error-plan-weight').classList.add('error');
                        }
                    }
                });
            });
       
            document.querySelector('.error').parentElement.scrollIntoView({behavior: 'smooth'});
        }
    }

    /**
    * Validates the custom date inputs by comparing the start and end dates.
    * If the end date is earlier than the start date, it adds an error class
    * to the select inputs and disables the create button.
    * Otherwise, it removes the error class and enables the create button.
    */
    controlCustomDate = () => {
        const startDay = document.getElementById('_start_date_3i').value;
        const startMonth = parseInt(document.getElementById('_start_date_2i').value) - 1;
        const startYear = document.getElementById('_start_date_1i').value;
        const endDay = document.getElementById('_end_date_3i').value;
        const endMonth = parseInt(document.getElementById('_end_date_2i').value) - 1;
        const endYear = document.getElementById('_end_date_1i').value;
        
        const completeStartedDate = new Date(startYear, startMonth, startDay);
        const completeEndedDate = new Date(endYear, endMonth, endDay);

        const form = document.querySelector('.form-new');
        const inputs = form.querySelectorAll('select');
        if(completeEndedDate < completeStartedDate) {
           
            inputs.forEach((select) => {
                select.classList.add('js-error');
            })
            disabledCreateButton()
        } else {
            inputs.forEach((select) => {
                select.classList.remove('js-error');
            })
            enabledCreateButton()
        }
    }

    /**
    * Displays a success message in a modal after a successful action on an item.
    * It sets the modal and overlay to be visible and updates the modal's content
    * with a confirmation message that includes the item and action.
    *
    * @param {string} item - The name of the item that was acted upon.
    * @param {string} action - The action that was performed on the item (e.g., 'created', 'updated').
    */
    displaySuccessMessage = (item, action) => {
        this.modaleNewDashboardTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleNewDashboardTarget.classList.add('modale-new--small');

        this.modaleNewDashboardTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>${item} ${action}</h3>
        </div>
        `;
        handleConfirmationMessage();
    }
}

