import Rails from "@rails/ujs";

const handleDrawGroupClick = () => {
    const drawGroupCards = document.querySelectorAll('.draw-group-card');
    drawGroupCards.forEach( (groupCard) => {
        groupCard.addEventListener("click", () => {
            drawGroupCards.forEach( (groupCard) => {
                groupCard.classList = "draw-group-card"
            })
          groupCard.classList.add('draw-group-card--selected');
        });
    });
}

const selectDefaultGroupCard = (index = 0) => {
    const groupCard = document.querySelectorAll('.draw-group-card')[index];
    if (groupCard) {
        groupCard.classList.add('draw-group-card--selected');
    }
    showDefaultDraws(index);
    
}

const showDefaultDraws = (index = 0) => {
    const defaultDrawGroup = document.querySelectorAll(".draw-group-card")[index];
    if (defaultDrawGroup) {
        const id = defaultDrawGroup.id; 

        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${id}/draws`,
            success: displayDraws.bind(this),
            error: (data) => {console.log(data)}
        });
    } else {
        const wrapperDraws = document.querySelector('.wrapper-draws');
        if (wrapperDraws) {
            const sections = wrapperDraws.querySelectorAll('section');
            sections.forEach((section) => {
                section.querySelector('.header-draws-title__counter').innerHTML = '(0)';
                const form = section.querySelector('form');
                let typeDraw = section.id;
                typeDraw = typeDraw.toUpperCase();
                const disclaimer = `<div class='draws-index__disclaimer-draws' data-projects-target='disclaimer${typeDraw}'>Aucun plan</div>`;
                form.innerHTML = disclaimer;
            })
            document.querySelectorAll('.header-draws-title__add').forEach((el) => {
                el.querySelector('i').id = "";
            })
        }
    }
}

function displayDraws(data) {
    const wrapperDraws = document.querySelector(".wrapper-draws");
    wrapperDraws.innerHTML= data.html;
}

export { handleDrawGroupClick, selectDefaultGroupCard, showDefaultDraws };