import { Controller } from "stimulus";
import { Dropzone } from "dropzone";
import Rails from "@rails/ujs";
import { handleConfirmationMessage } from "../utils/reload.js";
import { selectDefaultGroupCard, handleDrawGroupClick } from "../pages/showProject.js";
import { handleCheckbox, formatToUpperCaseCamelCase, openCollapse } from "../utils/tools.js";



export default class extends Controller { 
    static targets = [ "containerModal", "overlay", "containerNew", "containerShowDraw", "addNewDrawButton", "overlayShowDraw",
     "formIntervening", "overlayEditIntervening", "emergencyPhone", "displayPlanContainer", "wrapperDraws", "disclaimerEvacuation", "disclaimerCirculation",
    "preview", "projectTitle", "customTypeContainer", "newTypeContainer", "drawGroupsContainer", "evacuationContainer",
    "multiCheckBox", "evacuationCount", "circulationCount", "circulationContainer", "formNew", "drawGroupsCount", "blocInfos", "sectionDraws", "containerDropzone",
    "dropzonePlans", "formDocumentsIntervention", "formAccessPlans", "picturesContainer", "counterAttachments", "levelMin", "levelMax", "dropzoneInput", "wrapperFloor",
    "labelDropzonePlans", "labelDropzoneDocuments", "containerDocumentDropzone", "containerPlanDropzone", "containerPictoAccessDrawDropzone", "containerPlanAccessDrawDropzone",
    "deleteWarning", "warningOverlay", "buttonMultipleLevels", "buttonSingleLevel", "confirmationUpdateReference", "buttonClose", "gatheringPointContainer"];

    connect() {
        selectDefaultGroupCard();
    }

    //Action project

    showEditProject = (event) => {
        const token = event.currentTarget.dataset.token;

        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/edit`,
            success: (data) => {
                this.display(data, this.containerNewTarget);
            },
            error: (data) => { console.log(data) }
        });
    }

    reassignProject(event) {
        const token = event.currentTarget.dataset.token;
       
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/reassign`,
            success: (data) => {
                this.display(data, this.containerNewTarget);
            },
            error: (data) => console.log(data)
        });
    }

    alertNoDrawToDownload(event) {
        const [_data, _status, xhr] = event.detail;
        this.display(xhr.response, this.containerNewTarget)
        this.containerNewTarget.classList.add('modale-new--x-small')
    }


    updateProject = (event) => {
        const token = event.currentTarget.dataset.token
        const [_data, _status, xhr] = event.detail;
        this.blocInfosTarget.innerHTML = xhr.response;

        this.containerNewTarget.setAttribute('style', 'display: none');
        this.overlayTarget.setAttribute('style','dsiplay: none');
        this.updateReference(token);
    }

    

    updateReference = (token) => {
        const validationMessage = 
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet mis à jour</h3>
        </div>`;

        Rails.ajax({
            type: 'get',
            url: '/display_modale_update_reference',
            success: (data) => {
                this.containerNewTarget.setAttribute('style', 'display: flex');
                this.overlayTarget.setAttribute('style', 'display: flex');
                this.containerNewTarget.innerHTML = data.html;
                this.confirmationUpdateReferenceTarget.querySelector('.button-1').addEventListener('click', () => {
                    Rails.ajax({
                        type: 'patch',
                        url: `/projects/${token}/update_draws_reference`,
                        success: () => {
                          this.containerNewTarget.innerHTML =  validationMessage;
                          handleConfirmationMessage();
                        },
                        error: (error) => console.log(error)
                    });
                });
                this.confirmationUpdateReferenceTarget.querySelector('.button-close').addEventListener('click', () => {
                    this.containerNewTarget.innerHTML = validationMessage;
                    handleConfirmationMessage();
                })
            },
            error: (error) => console.log(error)
        })
    }

    updateReassignProject = () => {
        this.containerNewTarget.innerHTML =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet réassigné</h3>
        </div>
        `;

        const containerProjects = document.getElementById('projects');
        if(containerProjects) {
            const checkboxes = containerProjects.querySelectorAll('input[type=checkbox]');
           
            if(checkboxes.length > 0) {
                checkboxes.forEach( (el) => {
                    if(el.checked) {
                        el.checked = false;
                    }
                });
                this.containerButtonMultipleTarget.style.opacity = "0";
                this.containerButtonMultipleTarget.style.zIndex = "-1";
            }
        }
        
        handleConfirmationMessage();
    }


    //Action DrawGroup

    showNewDrawGroup = (event) => {
    
        const token = event.currentTarget.dataset.token;
        
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/draw_groups/new`,
            success: (data) => {
                this.display(data, this.containerNewTarget);
            },
            error: (data) => {console.log(data)}
        });
    }

    editDrawGroup = (event) => {
        const drawGroupId = event.currentTarget.id;

        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${drawGroupId}/edit`,
            success: (data) => {
                this.display(data, this.containerNewTarget);
            },
            error: (data) => {console.log(data)}
        });
    }

    appendDrawGroup = (event) => {
        const [_data, _status, xhr] = event.detail;
     
        this.drawGroupsContainerTarget.innerHTML = xhr.response;
        let counter = parseInt(this.drawGroupsCountTarget.innerHTML.split("")[1]) + 1;
        this.drawGroupsCountTarget.innerHTML = `(${counter})`; 
        
        handleDrawGroupClick();
        selectDefaultGroupCard();
        this.displaySuccessMessage("Groupe de plans", "crée");
    }

    showGatheringPoint(event) {
        const drawGroupId = event.currentTarget.id;

        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${drawGroupId}/display_gathering_point`,
            success: (data) => {
                this.display(data, this.displayPlanContainerTarget);
            },
            error: (data) => {console.log(data)}
        })
    }

    deleteDrawGroup = (event) => {
        const [_data, _status, xhr] = event.detail;
        const confirmationDeleteMessage = `
        <div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Groupe de plans supprimé</h3>
        </div>`;

        if(xhr.response.length == 0) {
            this.drawGroupsContainerTarget.innerHTML = `<div class="draw-group-container draw-group-container--disclaimer">Veuillez créer un nouveau groupe de plans</div>`
        }
        else {
            this.drawGroupsContainerTarget.innerHTML = xhr.response;
        }
        

        let counter = parseInt(this.drawGroupsCountTarget.innerHTML.split("").slice(1, -1).join(""));
        this.drawGroupsCountTarget.innerHTML = `(${counter - 1})`;
        this.display(confirmationDeleteMessage, this.containerNewTarget);
        handleConfirmationMessage();

        selectDefaultGroupCard();
        handleDrawGroupClick();
        
        this.getBlocInfos(event.currentTarget.dataset.projectToken);
    }



    //Action Type Intervening

    showNewTypeIntervening(event) {
        const projectToken = event.currentTarget.dataset.token;

        Rails.ajax({
            type: 'get',
            url: `/projects/${projectToken}/type_intervenings/new`,
            success: (data) => this.display(data, this.containerNewTarget),
            error: (data) => console.log(data)
        });
    }

    appendTypeIntervening() {
        this.displaySuccessMessage("Type d'intervenant", "Créé");
    }
    // Action Intervening

    addNewTypeIntervening() {
        Rails.ajax({
            type: 'get',
            url: `/generate_new_input`,
            success: (data) => {
                this.displayNewInput(data);
                this.formInterveningTarget.scrollIntoView({behavior: "smooth", bock: 'end'});
            },
            error: (data) => {console.log(data)}
        })
    }

    displayNewInput(data) {
        this.newTypeContainerTarget.insertAdjacentHTML('afterbegin', data.html);
    }

    // Action Draw

    showNewDraw = ((event) => {
        const drawGroupId = event.currentTarget.id;
        const description = event.currentTarget.dataset.description;
        if(drawGroupId) {
            Rails.ajax({
                type: 'get',
                url: `/draw_groups/${drawGroupId}/draws/new`,
                data: new URLSearchParams( {
                    description: description
                }).toString(),
                success: (data) => {
                  this.display(data, this.containerNewTarget)
                },
                error: () => {
                    document.querySelector('.draw-group-container--disclaimer').setAttribute('style', 'color: red');
                }
            });
        } else {
            document.querySelector('.draw-group-container--disclaimer').setAttribute('style', 'color: red');
        }
    })

    editDraw = (event) => {
        const drawId = event.currentTarget.id;
        
        Rails.ajax({
            type: 'get',
            url: `/draws/${drawId}/edit`,
            success: (data) => {
            this.display(data, this.containerNewTarget)
                
            },
            error: (data) => {console.log(data)}
        })
    }

    showDraws = (event) => {
        //A optimiser
        /* this.displayLoader(); */
        if(event) {
            const drawGroupId = event.currentTarget.id;

            Rails.ajax({
                type: 'get',
                url: `/draw_groups/${drawGroupId}/draws`,
                success: (data) => {
                    this.displayDraws(data);
                    this.addNewDrawButtonTarget.id = drawGroupId;
                },
                
                error: (data) => {console.log(data)}
            });
     
            
        }
        else {
            const defaultDrawGroupId = document.querySelectorAll(".draw-group-card")[0].id;

            Rails.ajax({
                type: 'get',
                url: `/draw_groups/${defaultDrawGroupId}/draws`,
                success: (data) => {
                    this.displayDraws(data);
                    this.addNewDrawButtonTarget.id = drawGroupId;
                },
                error: (data) => {console.log(data)}
            });
           
            this.addNewDrawButtonTarget.id = defaultDrawGroupId;
        }
    }

    newMassCreate = (event) => {
        const drawGroupId = event.currentTarget.id;
        const type = event.currentTarget.dataset.type;
        

        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${drawGroupId}/new_mass_draws`,
            data: new URLSearchParams( {
                type: type
            }).toString(),
            success: (data) => {
                this.display(data, this.containerNewTarget)
            },
            error: (data) => console.log(data)
        });
    }
      

    showWebApp= (event) => {
        const drawId = event.currentTarget.id;
        
        Rails.ajax({
            type: 'get',
            url: `/draws/${drawId}/previsualization`,
            success: this.displayWebApp.bind(this),
            error: (data) => {console.log(data)}
        })
    }

    showDrawFile = (event) => {
        const drawId = event.currentTarget.id;

        const attachment = event.currentTarget.dataset.attachment;

        Rails.ajax({
            type: 'get',
            url: `/draws/${drawId}/display_plan`,
            data: new URLSearchParams({
                attachment: attachment
            }),
            success: (data) => {
                this.display(data, this.displayPlanContainerTarget);
            },
            error: (data) => {console.log(data)}
        })
    }

    deleteDraws = (event) => {
        const [_data, _status, xhr] = event.detail;
        const drawType = _data["drawType"];
        const drawGroupId = _data["drawGroupId"];
        const projectToken = _data["projectToken"];

        this.getBlocInfos(projectToken);
        this.updateDrawGroupCard(drawGroupId);
        this.getDrawsSection(drawGroupId, drawType);
        this.displaySuccessMessage("Plan(s)", "supprimé(s)");

        event.stopPropagation();
    }

    displayDraws(data) {
        this.wrapperDrawsTarget.innerHTML= data.html;

        if (this.hasDisclaimerEvacuationTarget) {
            this.disclaimerEvacuationTarget.innerHTML = 'Auncun plan';
        }

        if (this.hasDisclaimerCirculationTarget) {
            this.disclaimerCirculationTarget.innerHTML = 'Auncun plan';
        }
    }

    appendDraw = (event) => {
        const [_data, _status, xhr] = event.detail;
        const drawType = _data["drawType"];
        const drawGroupId = _data["drawGroupId"];
        const projectToken = _data["projectToken"];

        this.getBlocInfos(projectToken);
        this.updateDrawGroupCard(drawGroupId);
        this.getDrawsSection(drawGroupId, drawType);
        this.displaySuccessMessage("Plan", "crée");
    }

    updateDraw = (event) => {
        const idDraw = event.currentTarget.id;
        const drawCards = Array.from(document.querySelectorAll('.draw-card'));
        const drawCard = drawCards.filter(el => el.id == idDraw)[0];
        const [_data, _status, xhr] = event.detail
        this.displaySuccessMessage("Plan", "modifié");
    } 

   getDrawsSection = (drawGroupId, drawType) => {
        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${drawGroupId}/draws`,
            data: new URLSearchParams({
                draw_type: drawType
            }),
            success: (data) => {
                const section = document.getElementById(drawType);
                section.outerHTML = data.html;
            },
            error: (data) => console.log(data)
        })
    }

    ////Form action

    showOptionFormIntervention = (event) => {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");

        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            element.scrollIntoView({behavior: "smooth", block: 'end'});
        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }

    }

    displayDeleteWarning = (event) => {
        const containerType = event.currentTarget.dataset.container;
        const attachment = event.currentTarget.dataset.attachment;
        const container = document.getElementById(containerType);

        if(containerType == "pictures-container") {
            const index = event.currentTarget.dataset.index;
            const drawId = event.currentTarget.dataset.draw;
            const drawType = event.currentTarget.dataset.drawtype;

            this.deleteWarningTarget.querySelector('.button-1').addEventListener('click', () => {
                this.deletePlanAttachment(attachment, index, drawId, containerType, drawType, container)
            })
         
        } else {
            const typeFile = event.currentTarget.dataset.typefile;
            const formatTypeFile = formatToUpperCaseCamelCase(typeFile);

            this.deleteWarningTarget.querySelector('.button-1').addEventListener('click', () => {
                this.deleteFileAttachment(attachment, containerType, container, typeFile, formatTypeFile)
            })
        }
        
        this.deleteWarningTarget.setAttribute('style', 'display: flex');
        this.warningOverlayTarget.setAttribute('style', 'display: flex');

        this.warningOverlayTarget.addEventListener('click', () => {
            this.removeDisplayWarning();
        })

        this.deleteWarningTarget.querySelector('.button-close').addEventListener('click', () => {
            this.removeDisplayWarning();
        })
    }

    removeDisplayWarning = () => {
        this.deleteWarningTarget.setAttribute('style', 'display: none');
            this.warningOverlayTarget.setAttribute('style','display: none');
    }

    deletePlanAttachment = (attachment, index, drawId, containerType, drawType, container) => {

        this.removeDisplayWarning();

        const disclaimer = ` <p>Aucun fichier rattaché</p>
                            <div class="collapse__wrapper__add" data-action="click->projects#generateNewInputPlan" data-container-target=${containerType}>
                                <p>Fichier supplémentaire</p>
                                <i class="fas fa-plus-circle"></i>
                            </div>`;
        const addDocument = `  <div class="collapse__wrapper__add" data-action="click->projects#generateNewInputPlan" data-container-target=${containerType}>
                                    <p>Fichier supplémentaire</p>
                                    <i class="fas fa-plus-circle"></i>
                                </div>`
        

        Rails.ajax({
            type: 'delete',
            url: `/draws/${drawId}/delete_plan_attachment`,
            data: new URLSearchParams({
                index: index,
                attachment: attachment,
                container_type: containerType,
            }),
            success: (data) => {
                container.innerHTML = data.html;
                if(drawType == "intervention") {
                    container.insertAdjacentHTML('beforeend', addDocument);
                } else {
                    container.insertAdjacentHTML('afterbegin', `<p>Aucun fichier rattaché</p>`)
                }
                
                if (container.querySelectorAll('.new-form-element__wrapper__pictures-container__picture').length == 0 && drawType == "intervention") {
                    container.innerHTML = disclaimer;
                }
                
            },
            error: (data) => console.log(data)
        })
    }

    deleteFileAttachment = (attachment, containerType, container, typeFile,  formatTypeFile) => {

        this.removeDisplayWarning();

        const disclaimer = `    <p>Aucun fichier rattaché</p>
                                <div class="collapse__wrapper__add" data-action="click->projects#displayForm${formatTypeFile}" data-container-target=${containerType}>
                                    <p>Fichier supplémentaire</p>
                                    <i class="fas fa-plus-circle"></i>
                                </div>`;

        const addDocument = `   <div class="collapse__wrapper__add" data-action="click->projects#displayForm${formatTypeFile}" data-container-target=${containerType}>
                                    <p>Fichier supplémentaire</p>
                                    <i class="fas fa-plus-circle"></i>
                                </div>`;
        
        Rails.ajax({
            type: 'delete',
            url: `/${typeFile}s/${attachment}`,
            data: new URLSearchParams({
                attachment: attachment,
                container_type: containerType,
            }),
            success: (data) => {
                container.innerHTML = data.html;
                container.insertAdjacentHTML('beforeend', addDocument);
                
                if (container.querySelectorAll('.new-form-element__wrapper__pictures-container__picture').length == 0) {
                    container.innerHTML = disclaimer;
                }
            },
            error: (data) => console.log(data)
        })
            
    }


    //AccessDraw method

    showAccessDrawFile = (event) => {
        const accessDrawId = event.currentTarget.dataset.attachment;

        Rails.ajax({
            type: 'get',
            url: `/access_draws/${accessDrawId}/display_plan`,
            success: (data) => {
                this.display(data, this.displayPlanContainerTarget);
            },
            error: (data) => {console.log(data)}
        })
    }

    //Display method
  



 
    // Display modal function

    display(data, container) {
        container.setAttribute('style','display: flex');
        
        if (container != this.displayPlanContainerTarget) {
            this.overlayTarget.style.display = "flex";
        }
        
        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            container.setAttribute('style','display: none');
        })
        if(data.html) {
            container.innerHTML = data.html;
        } else {
            container.innerHTML = data;
        }

        const button= document.querySelector(".button-close");

        if(button) {
            button.addEventListener("click", (event) => {
                container.setAttribute('style', 'display: none');
                this.overlayTarget.setAttribute('style','display: none');
            });
        }

        if(container.querySelector('form')) {
            const form = container.querySelector('form');
            const typeForm = form.dataset.container;

            switch (typeForm) {
                case "small":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                break;
                case "x-small":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                break;
                case 'form-reassign':
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                    break;
                default:
                    container.classList = 'modale-new';
            }
        } else {
            if(container.id != "display-plan-container") {
                container.classList = "modale-new modale-new--small"
            };
        }
      
    }

    displayWebApp(data) {
        this.containerShowDrawTarget.setAttribute('style', 'display: flex')
        this.overlayShowDrawTarget.setAttribute('style','display: flex');
        this.containerShowDrawTarget.innerHTML = data.html;

        const buttonClose = document.querySelector(".overlay-show-draw__close-previsualization");

        buttonClose.addEventListener("click", (event) => {
            this.containerShowDrawTarget.setAttribute('style', 'display: none');
            this.overlayShowDrawTarget.setAttribute('style','display: none');
        });

        this.overlayShowDrawTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            this.containerShowDrawTarget.setAttribute('style','display: none');
        })
    }


    hideDisplayPlan = () => {
        this.displayPlanContainerTarget.setAttribute('style', 'display: none');
    }

    //utils 

    errorNew(event) {
        this.containerNewTarget.setAttribute('style', 'display: flex');
        const [_data, _status, xhr] = event.detail;
        this.containerNewTarget.innerHTML = xhr.response;
  
        const levelsRangeError = document.getElementById('levels-range-error');
        if(levelsRangeError) {
            this.buttonMultipleLevelsTarget.checked = true;
            this.wrapperFloorTarget.classList.add("wrapper-floor--enabled");
            this.handleCounterAttachments();
            this.containerDropzoneTargets[0].classList.add('hidden');
        }

        
       /*  document.querySelectorAll('.validation').forEach((el) => {
            el.value == "" ?  el.classList.add('input-error') : null
        }); */
        document.querySelectorAll('.collapse__wrapper').forEach((wrapper) => {
            wrapper.querySelector('.field_with_errors') || wrapper.querySelector('.error') ? openCollapse() : null
        })
        
        this.triggerCloseButton();
    }

    handleCheckbox(event) {
        handleCheckbox(event);
    }


    getBlocInfos(projectToken) {
 
        Rails.ajax({
            type: 'get',
            url: `/projects/${projectToken}/get_bloc_infos/`,
            success: (data) => {
                this.blocInfosTarget.innerHTML = data.html;
            },
            error: (data) => { console.log(data) }
        })
    }

    updateDrawGroupCard = (drawGroupId) => {
        const container = this.drawGroupsContainerTarget;
        const currentDrawGroupCard = Array.from(container.querySelectorAll(".draw-group-card")).filter( card => card.id == drawGroupId)[0];

        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${drawGroupId}/get_draw_group_card`,
            success: (data) => {
                currentDrawGroupCard.outerHTML = data.html;
                const newCurrentDrawGroupCard = Array.from(container.querySelectorAll(".draw-group-card")).filter( card => card.id == drawGroupId)[0];
                newCurrentDrawGroupCard.classList.add('draw-group-card--selected');
                handleDrawGroupClick();
            },
            error: (data) => console.log(data)
        });
    }

    displayFormDocument = (event) => {
        if (event) {
            event.currentTarget.remove();
        }
   
        const allInputs = this.formDocumentsInterventionTarget.querySelectorAll('.new-form-element').length;
        const index = allInputs / 2
        const type = this.containerNewTarget.querySelector('form').action.includes("mass") ? "mass" : null;
    
        Rails.ajax({
            type: 'get',
            url: `/documents/generate_new_document_form/${index}`,
            data: new URLSearchParams({
                type: type
            }),
            success: (data) => {
                this.formDocumentsInterventionTarget.insertAdjacentHTML('beforeend', data.html);
                this.formDocumentsInterventionTarget.scrollIntoView({behavior: "smooth", block: 'end'});
            },
            error: (data) => { console.log(data) }
        });
        
        const disclaimer = Array.from(this.formDocumentsInterventionTarget.querySelectorAll('p')).find(el => el.innerHTML == "Aucun fichier rattaché");

        if (disclaimer) {
            disclaimer.remove();
        }
    }

    displayFormAccessDraw = (event) => {
        event.currentTarget.remove();
        const allInputs = this.formAccessPlansTarget.querySelectorAll('.new-form-element').length;
        const index = allInputs / 3;

        Rails.ajax({
            type: 'get',
            url: `/access_draws/generate_new_access_draw_form/${index}`,
            success: (data) => {
                this.formAccessPlansTarget.insertAdjacentHTML('beforeend', data.html);
                this.formAccessPlansTarget.scrollIntoView({behavior: 'smooth', block: 'end'})
            },
            error: (data) => { console.log(data) }
        })

        

        const disclaimer = Array.from(this.formAccessPlansTarget.querySelectorAll('p')).find(el => el.innerHTML == "Aucun fichier rattaché");

        if (disclaimer) {
            disclaimer.remove();
        }
      
    }

    generateNewInputPlan = (event) => {
        const levelSelection = event.currentTarget.dataset.levelSelectionTarget;
        const container = document.getElementById(event.currentTarget.dataset.containerTarget);
        const counterInput = container.querySelectorAll('.new-form-element__container-level-specification').length;

        Rails.ajax({
            type: 'get',
            url: `/generate_new_input_plan/${levelSelection}`,
            data: new URLSearchParams({
                counter_input: counterInput
            }),
            success: (data) => {
                container.insertAdjacentHTML('beforeend', data.html);
                container.scrollIntoView({behavior:'smooth', block:'end'});
            },
            error: (data) => { console.log(data) }
        })

        const disclaimer = Array.from(container.querySelectorAll('p')).find(el => el.innerHTML == "Aucun fichier rattaché");

        if (disclaimer) {
            disclaimer.remove();
        }
    }

    checkLevelsRange = () => {
        
        const maxLevel = parseInt(document.getElementById('draw_ended_floor').value);
        const minLevel = parseInt(document.getElementById('draw_started_floor').value);
    
            if (minLevel !== "" && maxLevel !== "" && maxLevel < minLevel) {
                document.getElementById('draw_ended_floor').classList.add('js-error')
                document.getElementById('draw_started_floor').classList.add('js-error')
               }
        
            else {
                document.getElementById('draw_ended_floor').classList.remove('js-error')
                document.getElementById('draw_started_floor').classList.remove('js-error')
            }
            
        }
      
    checkRange = (event) => {
        let level = parseInt(event.currentTarget.value.replace(/\s+/g, ''));
        
        if (isNaN(level) == true) {
            event.currentTarget.value = null;
            event.currentTarget.placeholder = "Veuillez rentrer un nombre";
            event.currentTarget.classList.add('js-error');
        }
        else {
            event.currentTarget.value = level;
            this.checkLevelsRange();
        }
    }

    handleCounterAttachments = () => {
        let valueMin = "";
        let valueMax = "";
        let totalExpected = "";

        const totalFile = this.containerDropzoneTargets[1].querySelectorAll('.dz-complete').length;

        if(this.levelMinTarget.value) {
            valueMin = this.levelMinTarget.value;
        }
        if(this.levelMaxTarget.value) {
            valueMax = this.levelMaxTarget.value;
        }

        if(valueMin !== "" && valueMax !== "") {
            totalExpected = Array.from({ length: valueMax - valueMin + 1}).length;
            if(totalExpected !== 0) {
                this.counterAttachmentsTarget.innerHTML = `<p class="total-file-counter">${totalFile}</p> / <p class="expected-file">${totalExpected}</p>`;
                this.containerDropzoneTargets[1].classList.remove('hidden');
               /*  this.wrapperFloorTarget.querySelectorAll('.error-text') && this.wrapperFloorTarget.querySelectorAll('.error-text').forEach( el => el.remove()); */
                
            }
        }

        this.checkCounterAttachments(totalFile, totalExpected)
    }

    checkCounterAttachments = (totalFile, totalExpected) => {
        if (totalFile > totalExpected) {
            this.counterAttachmentsTarget.setAttribute('style', 'color: red');
        }
        else if (totalFile == totalExpected) {
            this.counterAttachmentsTarget.setAttribute('style', 'color: green');
        }
        else {
            this.counterAttachmentsTarget.setAttribute('style', 'color: black');
        }
    }

    downloadDocument = (event) => {
        const documentId = event.currentTarget.dataset.attachment;
        Rails.ajax({
            type: 'get',
            url: `/documents/${documentId}/download_document`,
            success: (data) => console.log(data),
            error: (data) => console.log(data)
        })
    }

    handleNavigationBetweenPlans = (event) => {
        const type = event.currentTarget.dataset.type;
        const attachmentId = event.currentTarget.dataset.attachment;
        const drawId = event.currentTarget.dataset.draw;

        Rails.ajax({
            type: 'get',
            url: `/draws/${drawId}/handle_navigation_between_plans`,
            data: new URLSearchParams({
                attachment_id: attachmentId,
                type: type
            }),
            success: (data) => this.displayPlanContainerTarget.innerHTML = data.html,
            error: (data) => console.log(data)
        })
    }

    controlDropzoneErrors(event) {
        const errors = document.querySelectorAll('.dz-error');
        
            event.preventDefault();
            const errorMessage = `<p class="error-plan-weight">Veuillez vérifier ce champ</p>`;
            const gatheringPointContainers = this.gatheringPointContainerTargets;
            const documentsContainers = this.containerDocumentDropzoneTargets;
            const plansContainers = this.containerPlanDropzoneTargets;
            const accessDrawPictoContainers = this.containerPictoAccessDrawDropzoneTargets;
            const accessDrawPlanContainers = this.containerPlanAccessDrawDropzoneTargets;
            const containers = [gatheringPointContainers, documentsContainers, plansContainers, accessDrawPictoContainers, accessDrawPlanContainers];
           
            containers.forEach((container) => {
                container.forEach((el) => {
                    if(el.querySelector('.dz-error')) {
                
                        const containerMessage = el.parentElement.querySelector('label');
    
                        if(containerMessage.querySelector(".error-plan-weight") == null) {
                            containerMessage.insertAdjacentHTML('beforeend', errorMessage);
                            containerMessage.querySelector('.error-plan-weight').classList.add('error');
                        }
                    }
                });
            })
            document.querySelector('.error').parentElement.scrollIntoView({behavior: 'smooth'})
        
    }

    toggleDisplayMultipleLevelInputs(event) {
        if(!this.wrapperFloorTarget.classList.contains('wrapper-floor--enabled') && event.currentTarget.value == "multiple_level") {
            document.getElementById('draw_started_floor').value = "";
            document.getElementById('draw_ended_floor').value = "";
            this.wrapperFloorTarget.classList.add("wrapper-floor--enabled");
            this.containerDropzoneTargets[0].classList.add('hidden');
            const clickElements = this.containerDropzoneTargets[0].querySelectorAll('.dz-remove');
         
            if(clickElements.length > 0) {
                clickElements.forEach((element) => {
                    element.click()
                })
            }
        } else if (this.wrapperFloorTarget.classList.contains('wrapper-floor--enabled') && event.currentTarget.value == "single_level"){
            this.wrapperFloorTarget.classList = "wrapper-floor";
            this.containerDropzoneTargets[0].classList.remove('hidden');
            if(!this.containerDropzoneTargets[1].classList.contains('hidden')) {
                this.containerDropzoneTargets[1].classList.add('hidden');
            }
            const clickElements = this.containerDropzoneTargets[1].querySelectorAll('.dz-remove');
            if(clickElements.length > 0) {
                clickElements.forEach((element) => {
                    element.click()
                })
            }
        }
    }

    triggerCloseButton() {
        document.querySelector('.button-close').addEventListener("click", () => {
            this.containerNewTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
    }

    displaySuccessMessage = (item, action) => {
        this.containerNewTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.containerNewTarget.classList.add('modale-new--small');

        this.containerNewTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>${item} ${action}</h3>
        </div>
        `;
        handleConfirmationMessage();
    }

    displayLoaderOnPostRequest = (event) => {
        const errors = document.querySelectorAll('.dz-error');

        if(errors.length > 0) {
            this.controlDropzoneErrors(event);
        } else {
            this.containerNewTarget.setAttribute('style', 'display: none');
        }
        
    }
}
