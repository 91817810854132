const hideModaleInformationsDraw = () => {
    const buttonsClose = document.querySelectorAll(".close-modale-show");
  

    if(buttonsClose) {
        
        buttonsClose.forEach((button) => {
            const video = document.querySelector("video");
            const modaleInformations = document.querySelector(".modale-informations");
            const modaleMedia = document.querySelector(".modale-media");
            button.addEventListener("click", () => {
                if (video) {
                    video.pause();
                }
               
                modaleInformations.style.display= "none";
                modaleMedia.style.display= "none";
            });
        });
    }
}

export { hideModaleInformationsDraw };