import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { handleConfirmationMessage } from "../utils/reload.js";
import { handleCheckbox, openCollapse, getUrlParams } from "../utils/tools.js"

export default class extends Controller {

    static targets = ["overlay", "modaleUser", "buttonClose", "labelOrganizationId", "resetFilterButtonCustomer", "projectsContainer", "projectsCounterContainer",
                    "resetFilterButtonCustomer", "customersContainer", "projectsCount", "blocInfos", "inputRetailer", "inputReferent", "referentField", "projectsCount", 
                    "customersCounterContainer", "containerButtonMultiple", "addProject", "userSelectedBlocProjects", "addCustomer", "userSelectedBlocCustomers" ]

    connect() {
        this.element.addEventListener('turbo:frame-render', (event) => {
            const url = new URL(event.detail.fetchResponse.response.url)
            const params = new URLSearchParams(url.search);
            const selectedCustomer = params.get('selected_customer');
         
            if(selectedCustomer) {
                const customerCard = Array.from(document.querySelectorAll(`.customer-card`)).filter( card => card.id == selectedCustomer)[0];
                customerCard ? customerCard.classList.add('customer-card--selected') : null;
            }
            
        });
    }
    //user functions 

    editUser(event) {
        const token = event.currentTarget.dataset.userToken;
        
        Rails.ajax({
            type: 'get',
            url: `/admins/users/${token}/edit`,
            data: new URLSearchParams({
                page: "user"
            }),
            success: (data) => {
                this.display(data, this.modaleUserTarget)
            },
            error: (data) => {console.log(data)}
        });
    }

    updateUser = (event) => {
        const [_data, _status, xhr] = event.detail;
        const confirmationMessage =      `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Utilisateur mis à jour</h3>
        </div>`;
        this.blocInfosTarget.innerHTML = xhr.response;
        this.modaleUserTarget.innerHTML = confirmationMessage;
        handleConfirmationMessage();
    }

    /////////////////////////////////////////////////////////////////////////////////////////

    //Customer functions

    editCustomer = (event) => {
        const customerToken = event.currentTarget.dataset.token;
        const userToken = document.querySelector('.main-container-user').dataset.userToken;

        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/edit`,
            data: new URLSearchParams({
                page: "user",
                user_token: userToken
            }),
            success: (data) => this.display(data, this.modaleUserTarget),
            error: (data) => { console.log(data) }
        })
    }

    updateCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const location = window.location.href.split('/');
        const userToken = location[location.length -1];
        const url = xhr.responseURL.split("/");
        let customerToken = url[url.length-1];
    
        const customerCardUpdated = document.getElementById(`${customerToken}`);
        const customerUpdatedIsSelected = customerCardUpdated.classList.contains('customer-card--selected');
        const otherCardIsSelected = Array.from(document.querySelectorAll('.customer-card')).filter( card => card.classList.contains('customer-card--selected'));
        
        this.getCustomerCard(userToken, customerToken, customerUpdatedIsSelected);
      
        
        
        if (otherCardIsSelected.length > 0 && otherCardIsSelected[0].id != customerToken) {
            const otherCardSelected = otherCardIsSelected[0];
            this.getBlocProjects(userToken, otherCardSelected.id);
            this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, otherCardSelected.id);
            
        } else  {
       
            if (customerUpdatedIsSelected) {
                this.getBlocProjects(userToken, customerToken);
                this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, customerToken);
            } else {
                this.getBlocProjects(userToken, null);
                this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, null);
            }
            
        }

        this.modaleUserTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>Client mis à jour</h3>
        </div>
        `;

        handleConfirmationMessage();
    }

    desactivateCustomer = (event) => {
        const url = window.location.href.split('/');
        const userToken = url[url.length -1];
        const customer = event.currentTarget.closest('.customer-card');
        let customerToken = customer.id;
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let filteredCustomerToken = null;

        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer.id;
        }

        this.displayLoader();
  
        Rails.ajax({
            type: 'patch',
            url: `/customers/${customerToken}/desactivate_related_project`,
            data: new URLSearchParams({
                filtered_customer_token: filteredCustomerToken,
                filtered_user_token: userToken
            }), 
            success: (data) => {
                this.removeLoader();
                this.getCustomerCard(userToken, customerToken, customerToken == filteredCustomerToken);
                this.projectsContainerTarget.innerHTML = data.html;
            },
            error: (data) => console.log(data)
        });
    }

    deleteCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const customerUrl = xhr.responseURL.split("/");
        let customerToken = customerUrl[customerUrl.length-1];

        const userURL = window.location.href.split('/');
        const userToken = userURL[userURL.length -1];
        let filteredCustomerToken = null

        const selectedCustomer = document.querySelector('.customer-card--selected') ? document.querySelector('.customer-card--selected').id : null;
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer;
        }

      
        
        this.getBlocCustomers(userToken, filteredCustomerToken);
        this.getBlocProjects(userToken, filteredCustomerToken != customerToken ? filteredCustomerToken : null );
        this.handleCounter("customers", this.customersCounterContainerTarget, userToken, filteredCustomerToken);
        this.handleCounter("projects", this.projectsCounterContainerTarget, userToken, filteredCustomerToken != customerToken ? filteredCustomerToken : null);
        this.getBlocInfos(userToken)

        if(this.resetFilterButtonCustomerTarget.style.opacity == "1") {
            this.resetFilterButtonCustomerTarget.style.opacity = "0";
        }

        const confirmationDeleteMessage =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Client supprimé</h3>
        </div>`;

        this.modaleUserTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleUserTarget.classList.add('modale-new--small');
        this.modaleUserTarget.innerHTML = confirmationDeleteMessage;
        handleConfirmationMessage();
    
    }

    /////////////////////////////////////////////////////////////////////////////////////////

    // project functions

    reassignProject = (event) => {
        const token = event.currentTarget.dataset.token;
       
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/reassign`,
            success: (data) => this.display(data, this.modaleUserTarget),
            error: (data) => console.log(data)
        });
    }

    updateReassignProject = () => {
        const checkboxMultiple = document.getElementById('multipleCheck');
        const userURL = window.location.href.split('/');
        const userToken = userURL[userURL.length -1];
        let filteredCustomerToken = null;

        const selectedCustomer = document.querySelector('.customer-card--selected') ? document.querySelector('.customer-card--selected').id : null;

        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer;
        }
    
        this.modaleUserTarget.innerHTML =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet(s) réassigné(s)</h3>
        </div>
        `;
        

        if(selectedCustomer) {
            this.getCustomerCard(userToken, selectedCustomer, true);
        } else {
            this.getBlocCustomers(userToken, selectedCustomer)
        }
        
        
        this.getBlocProjects(userToken, selectedCustomer);
        this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, selectedCustomer);
        
        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }

        handleConfirmationMessage();
    }

    deleteProject = (event) => {
        const userURL = window.location.href.split('/');
        const userToken = userURL[userURL.length -1];
        let filteredCustomerToken = null;

        const selectedCustomer = document.querySelector('.customer-card--selected') ? document.querySelector('.customer-card--selected').id : null;
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer;
        }

        this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, filteredCustomerToken);
        this.handleCounter('customers', this.customersCounterContainerTarget, userToken, filteredCustomerToken);
        this.getBlocProjects(userToken, filteredCustomerToken);
       
        if(selectedCustomer == null) {
            this.getBlocCustomers(userToken, null);
        } else {
            this.getCustomerCard(userToken, selectedCustomer, true);
        }

        this.getBlocInfos(userToken);
        
        const confirmationDeleteMessage =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet supprimé</h3>
        </div>`;

        this.modaleUserTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleUserTarget.innerHTML = confirmationDeleteMessage;
        this.modaleUserTarget.classList.add('modale-new--small')
        
        handleConfirmationMessage();

        
        event.stopPropagation();
    }

    desactivateProject = (event) => {
        const [_data, _status, xhr] = event.detail;
        const userURL = window.location.href.split('/');
        const userToken = userURL[userURL.length -1];
        const card = event.currentTarget.closest('.project-card');

        card.outerHTML = _data.html || _data;
        
        let filteredCustomerToken = null;

        const selectedCustomer = document.querySelector('.customer-card--selected') ? document.querySelector('.customer-card--selected').id : null;
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer;
        }
        
        if(selectedCustomer) {
            this.getCustomerCard(userToken, filteredCustomerToken, true)
        } else {
            this.getBlocCustomers(userToken, filteredCustomerToken)
        }

        event.stopPropagation();
    }

    deleteRelatedProjects = (event, customerId) => {
        const organizationId = event.currentTarget.dataset.organization;
        const selected = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        const compareId = (card) => {
            const regex = /\d+/;
            let id = card.dataset.customer;
            id = [...id.match(regex)][0];
            return customerId == id
        }
        const projectCards = Array.from(document.querySelectorAll(".project-card")).filter(compareId);
        projectCards.forEach(card => card.remove());

        if(this.projectsContainerTarget.querySelector(".project-card") == null) {
            Rails.ajax({
                type: "get",
                url: "/projects",
                data: new URLSearchParams({
                    selected: selected,
                    organization_id: organizationId
                }),
                success: (data) => {
                    this.projectsContainerTarget.innerHTML = data.html;
                    this.projectsCountTarget.innerHTML = this.projectsContainerTarget.querySelectorAll('.project-card').length;
                    document.querySelector(".project-title__group__selected-elements").innerHTML = '';
                },
                error: (data) => { console.log(data) }
            })
        }

    }

    deleteMassProjects = (event) => {
        const checkboxMultiple = document.getElementById('multipleCheck');
        let filteredCustomerToken = null;
        const userURL = window.location.href.split('/');
        const userToken = userURL[userURL.length -1];

        const selectedCustomer = document.querySelector('.customer-card--selected') ? document.querySelector('.customer-card--selected').id : null;
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer;
        }
        
        if(selectedCustomer) {
            this.getCustomerCard(userToken, filteredCustomerToken, true)
        } 
        else {
            this.getBlocCustomers(userToken, filteredCustomerToken);
        }
        this.getBlocProjects(userToken, filteredCustomerToken);
        this.handleCounter("projects", this.projectsCounterContainerTarget, userToken, filteredCustomerToken);

       

        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }

        this.containerButtonMultipleTarget.style.opacity = "0";
        this.containerButtonMultipleTarget.style.zIndex = "-1";
        this.overlayTarget.style.display = "none";

        event.stopPropagation(); 
    }

    /////////////////////////////////////////////////////////////////////////////////////////

    //display functions


    display(data, container) {
        this.overlayTarget.setAttribute('style', 'display: flex');
        container.setAttribute('style','display: flex');
        if(data.html) {
            container.innerHTML = data.html;
        } else {
            container.innerHTML = data;
        }
        
        const button= document.querySelector(".button-close");

        if(button) {
            button.addEventListener("click", (event) => {
                container.setAttribute('style', 'display: none');
                this.overlayTarget.setAttribute('style','display: none');
            });
        }

        if (this.hasPreviewTarget) {
            this.handleInputDraw();
        }

        if(container.querySelector('form')) {
            const form = container.querySelector('form');
            const typeForm = form.dataset.container;

            switch (typeForm) {
                case "form-edit-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleRetailersList();
                    this.handleReferentsList();
                    break;
                case "form-edit-user":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    break;
                case "form-new-project":
                case "form-reassign":
                case "form-mass-reassign":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                    break;
                default:
                    container.classList = 'modale-new';
            }
        } else {
            container.classList = "modale-new modale-new--small"
        }

        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            container.setAttribute('style','display: none');
        });
    }

    displayResetFilterCustomer() {
        this.resetFilterButtonCustomerTarget.style.opacity = "1";
        this.resetFilterButtonCustomerTarget.style.zIndex = "0";

    }

    displayReferentForm(event) {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");
        const form = document.querySelector('.form-new');
      
       
        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            form.scrollTo({top: form.offsetHeight, behavior: 'smooth'})

        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }
    }

    resetSelectedCustomer= () => {
        
        const customerCards = document.querySelectorAll(".customer-card");
        customerCards.forEach((card) => {
            card.classList.remove('customer-card--selected');
        })
    }

    displayData(data, container) {
        container.innerHTML = data.html;
    }


    /////////////////////////////////////////////////////////////////////////////////////////

    //Filter functions

    launchCustomerFilter = (event) => {
        this.displayLoader();
        this.customerFilter(event);
        this.getCountSelectedCustomerProjects(event);
        this.displayResetFilterCustomer();
    }

    customerFilter = (event) => {
        this.resetSelectedCustomer();
        const customerToken = event.currentTarget.dataset.customerToken;
        const url = window.location.href.split('/');
        const userToken = url[url.length -1];

       
        event.currentTarget.parentNode.classList.add('customer-card--selected');

        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/filter_projects_by_customer`,
            data: new URLSearchParams({
                user_token: userToken
            }),
            success: (data) => {
                this.displayData(data, this.projectsContainerTarget);
                this.removeLoader();
            },
            error: (data) => { console.log(data) }
        });
        
    }


    getCountSelectedCustomerProjects = (event) => {
        const selectedUser = document.querySelector(".customer-title__group__selected-user");
        const customerToken = event.currentTarget.dataset.customerToken;
        const url = window.location.href.split('/');
        const userToken = url[url.length -1];

        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/counter_projects_by_customer/`,
            data: new URLSearchParams({
                user_page: true,
                user_token: userToken
            }),
            success: (data) => { 
                this.displayData(data, this.projectsCounterContainerTarget);
            },
            error: (data) => { console.log(data) }
        })
    }


    undoCustomersFilter = (event) => {

        const url = window.location.href.split('/');
        const userToken = url[url.length -1];
        const selectedUser = document.querySelector(".user-card--selected");

        this.getBlocCustomers(userToken, null);
        this.getBlocProjects(userToken, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, userToken, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, null);
        this.removeSortItem(this.customersLegendTarget);
        this.removeSortItem(this.projectsLegendTarget);
        event.stopPropagation();
    }

    resetSelectedCustomer= () => {
        
        const customerCards = document.querySelectorAll(".customer-card");
        customerCards.forEach((card) => {
            card.classList.remove('customer-card--selected');
        })
    }

    removeResetFilterCustomer() {
        this.resetFilterButtonCustomerTarget.style.opacity = "0";
        this.resetFilterButtonCustomerTarget.style.zIndex = "-1";
    }

    /////////////////////////////////////////////////////////////////////////////////////////

    //tools functions

    handleCheckbox(event) {
        handleCheckbox(event);
    }

    errorNew(event) {
        const [_data, _status, xhr] = event.detail;
        this.modaleUserTarget.innerHTML = xhr.response;
      
        const levelsRangeError = document.getElementById('levels-range-error');
        if(levelsRangeError) {
            this.buttonMultipleLevelsTarget.checked = true;
            this.wrapperFloorTarget.classList.add("wrapper-floor--enabled");
            
        }
        
        openCollapse();
        this.triggerCloseButton();
    }

    triggerCloseButton() {
        this.buttonCloseTarget.addEventListener("click", () => {
            this.modaleUserTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
    }

    massReassign = () => {
        const projectTokens = [];

        document.querySelectorAll('input:checked').forEach((element) => {
            projectTokens.push(element.value)
        });
        
        Rails.ajax({
            type: 'get',
            url: "/projects/multiple_reassign_edit",
            data: new URLSearchParams({
                project_tokens: projectTokens
            }),
            success: (data) => this.display(data, this.modaleUserTarget),
            error: (data) => {console.log(data)}
        })
    }

    alertNoDrawToDownload(event) {
        const [_data, _status, xhr] = event.detail;
        this.display(xhr.response, this.modaleUserTarget);
        this.modaleUserTarget.classList.add('modale-new--x-small');
    }

    displayLoader() {
        this.overlayTarget.style.display = "block";
    }

    removeLoader() {
        this.overlayTarget.style.display = "none";
    }

    displayFilter(data, container) {
        container.innerHTML = data.html;
    }

    desactivateItem = (event) => {
        const card = event.currentTarget.closest(event.params.typecard);
        card.outerHTML = event.detail[0].html;

        if(event.params.typecard == ".project-card") {
            const organizationToken = card.dataset.organization;

            Rails.ajax({
                type: 'get',
                url: `/customers`,
                data: new URLSearchParams({
                    organization_token: organizationToken
                }),
                success: (data) => {
                    const filteredCustomers = Array.from(document.querySelectorAll('.customer-card')).filter( card => card.classList.contains('customer-card--selected'));
                    this.displayFilter(data, this.customersContainerTarget);
                    
                    if (filteredCustomers.length > 0) {
                        Array.from(document.querySelectorAll('.customer-card')).filter( card => card.id == filteredCustomers[0].id)[0].classList.add("customer-card--selected")
                    }
                 },
                error: (data) => { console.log(data) }
            })  
        }
    }

    handleCounter = (type, counterContainer, selectedUser, selectedCustomer) => {
        switch (type) {
            case 'users':
                Rails.ajax({
                    type: 'get',
                    url: '/admins/users/counter_users',
                    success: (data) => {
                        counterContainer.outerHTML = data.html
                    }
                });
                break;
            case 'customers':
                Rails.ajax({
                    type: 'get',
                    url: '/customers/counter_customers',
                    data: new URLSearchParams({
                        selected_user: selectedUser,
                }),
                    success: (data) => {
                        counterContainer.innerHTML = data.html;
                        this.addCustomerTarget.style.display = "none";
                        this.userSelectedBlocCustomersTarget.style.display = "none";
                    }
                });
                break;
            case 'projects':
            Rails.ajax({
                type: 'get',
                url: '/projects/get_counter_projects',
                data: new URLSearchParams({
                    selected_customer: selectedCustomer,
                    selected_user: selectedUser
                }),
                success: (data) => {
                    counterContainer.innerHTML = data.html
                    this.addProjectTarget.style.display = "none";
                    this.userSelectedBlocProjectsTarget.style.display = "none";
                }
            });
            break;
            default: null
        } 

    }

    handleRetailersList = () => {
        if(this.hasInputOrganizationTarget && this.hasInputRetailerTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                const organizationId = event.currentTarget.value;

                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationId}/retailers_list`,
                    success: (data) => {
                 
                        this.inputRetailerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
               
                const retailerId = null
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    data: new URLSearchParams({
                        organization_id: organizationId
                    }),
                    success: (data) => {
                        
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                }); 
            })
        }
    }


    handleReferentsList = () => {
        if(this.hasInputRetailerTarget) {
            this.inputRetailerTarget.addEventListener("change", (event) => {
                const retailerId = event.currentTarget.value;
                this.referentFieldTarget.classList.remove('hidden')
                
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    success: (data) => {
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                });
            });
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////// 

    //sort functions

    sortItems = (event) => {
        this.displayLoader();
        const item = event.currentTarget.dataset.item;
        const column = event.currentTarget.dataset.column;
        const arrow = event.currentTarget.querySelector("i");
        const container = document.querySelector(`.${item}s-cards-container`);
        let cssClass = "rotate--visible";

        //Get user token
        const url = window.location.href.split('/');
        const userToken = url[url.length - 1];

       
        //Check for selected Organization
        const organizationId = event.currentTarget.dataset.organization
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");

        //Check for selected Customer
        let customerToken = null;
        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        //Get selected cards for display
        const filteredItems = Array.from(document.querySelectorAll(`.${item}-card`)).filter( card => card.classList.contains(`${item}-card--selected`));
        const blocIndex = event.currentTarget.parentNode;
        const sortableItems = Array.from(blocIndex.querySelectorAll(".sortable"));
        const itemNotSelected = sortableItems.filter(element => element != event.currentTarget);
        

        itemNotSelected.forEach((element) => {
            if(element.querySelector("i").classList.contains("rotate--visible") || element.querySelector("i").classList.contains("rotate--180")) {
                sortableItems.forEach(element => element.querySelector("i").classList = "fas fa-arrow-down rotate")
            }
        });

        if(event.currentTarget.querySelector("i").classList.contains("rotate--visible")) {
             cssClass = "rotate--180";
        }
        else if(event.currentTarget.querySelector("i").classList.contains("rotate--180")) {
            cssClass = "";
        }
       
        
        Rails.ajax({
            type: 'get',
            url: `/launch_sort_${item}s`,
            data: new URLSearchParams({
                item: item,
                column: column,
                css_class: cssClass,
                selected_organization: selectedOrganization,
                organization_id: organizationId,
                user_token : userToken,
                customer_token: customerToken
            }),
            success:(data) => {
                this.removeLoader();
                container.innerHTML = data.html;
                arrow.classList = `fas fa-arrow-down rotate ${cssClass}`;
               
                if(userToken || customerToken) {
                    if(userToken) {
                        const userCard = document.getElementById(userToken);
                        if(userCard) {
                            userCard.classList.contains('user-card--selected') ? null : userCard.classList.add(`user-card--selected`)
                        }
                    }

                    if(customerToken) {
                        const customerCard = document.getElementById(customerToken);
                        if(customerCard) {
                            customerCard.classList.contains('customer-card--selected') ? null : customerCard.classList.add(`customer-card--selected`)
                        }
                    }
                } 
             },
            error: (data) => { console.log(data) }
        })
    }


    removeSortItem = () => {
        const items = document.querySelectorAll(".rotate");
        items.forEach( item => item.classList = "fas fa-arrow-down rotate")
    }

    getBlocInfos = (userToken) => {
        const user = userToken;

        Rails.ajax({
            type: 'get',
            url: `/admins/users/${user}/get_bloc_infos`,
            success: (data) => {
                this.blocInfosTarget.innerHTML = data.html;
            },
            error: (data) => { console.log(data) }
        })
    }

    ////////////////////////////////////////////////////////////

    // API calls

     // API calls
    
     getBlocUsers = (userToken) => {

        Rails.ajax({
            type: 'get',
            url: `/admins/users`,
            data: new URLSearchParams({
                selected_user: userToken
            }),
            success: (data) => {
                this.usersContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.usersLegendTarget);
                if(userToken && document.getElementById(`${userToken}`)) {
                    document.getElementById(`${userToken}`).classList.add('user-card--selected');
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    getUserCard = (userToken) => {
        Rails.ajax({
            type: 'get',
            url: `/admins/users/${userToken}/get_user_card`,
            success: (data) => {
                document.getElementById(`${userToken}`).outerHTML = data.html;
                document.getElementById(`${userToken}`).classList.add('user-card--selected');
                
            },
            error: (error) => { console.log(error) }
        });
    }

    getBlocCustomers = (userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/customers`,
            data: new URLSearchParams({
                selected_user: userToken,
                selected_customer: customerToken
            }),
            success: (data) => {
                this.customersContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.customersLegendTarget);
               
                if(customerToken && document.getElementById(`${customerToken}`)) {
                    document.getElementById(`${customerToken}`).classList.add('customer-card--selected');
                }
               
                if(this.customersContainerTarget.querySelector(".customer-card") == null) {
                    this.customersContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de clients existant</p>";
                }
                
                if(customerToken && document.getElementById(`${customerToken}`)) {
                    this.resetFilterButtonCustomerTarget.style.opacity = "1";
                    this.resetFilterButtonCustomerTarget.style.zIndex = "0";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    getCustomerCard = (userToken, customerToken, selected) => {
        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/get_customer_card`,
            data: new URLSearchParams({
                selected_user: userToken
            }),
            success: (data) => {
                document.getElementById(`${customerToken}`).outerHTML = data.html;
                selected ? document.getElementById(`${customerToken}`).classList.add('customer-card--selected') : null;
            },
            error: (error) => { console.log(error) } 
        });
    }

    getBlocProjects = (userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/projects`,
            data: new URLSearchParams({
                user_token: userToken,
                customer_token: customerToken
            }),
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.projectsLegendTarget);

                if(this.projectsContainerTarget.querySelector(".project-card") == null) {
                    this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de projet existant</p>";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    //////////
}