import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { handleConfirmationMessage } from "../utils/reload.js";
import { handleCheckbox, openCollapse } from "../utils/tools.js";

/**
 * Contains all the functions relative to the customers show page.
 * @class
 * @alias CustomersController
 */
export default class extends Controller {
    static targets = ["overlay", "modaleCustomer", "buttonClose", "disclaimer", "projectsContainer", "projectsCount", "preview", "containerButtonMultiple", 
    "inputOrganization", "inputRetailer", "referentField", "inputReferent", "blocInfos", "projectsCounterContainer", "customerSelectedBlocProjects"];
    

    connect() {
    }
    // Project method

    /**
   * Send an AJAX request to show the new project form for a customer.
   * @param {Event} event - The event triggered by the user's action.
   * @param {HTMLElement} event.currentTarget - The target element that triggered the event.
   * @param {string} event.currentTarget.dataset.customerToken - The customer token from the data attribute.
   * @param {string} event.currentTarget.dataset.organizationToken - The organization token from the data attribute.
   */
    showNewProject = (event) => {
        const customerToken = event.currentTarget.dataset.customerToken;
        const organizationToken = event.currentTarget.dataset.organizationToken;
        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/projects/new`,
            data: new URLSearchParams({
                organization_token: organizationToken
            }),
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => {console.log(data)}
        });
    }

    /**
     * Updates the reassign project modal with a confirmation message and resets checkboxes.
     * 
     * This function updates the inner HTML of the `modaleCustomerTarget` with a confirmation
     * message. It then finds all checkboxes on the page and unchecks any that are checked.
     * Finally, it sets the opacity and z-index of `containerButtonMultipleTarget` to hide it,
     * and calls `handleConfirmationMessage` to handle the display of the confirmation message.
     */
    updateReassignProject = () => {
        this.modaleCustomerTarget.innerHTML =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet(s) réassigné(s)</h3>
        </div>
        `;
        const checkboxes = document.querySelectorAll('input[type=checkbox]');
        if(checkboxes.length > 0) {
            checkboxes.forEach( (el) => {
                if(el.checked) {
                    el.checked = false;
                }
            });
            this.containerButtonMultipleTarget.style.opacity = "0";
            this.containerButtonMultipleTarget.style.zIndex = "-1";
        }
        handleConfirmationMessage();
    }
    //////

    // Customer method
    /**
     * Shows the edit form for a customer.
     * 
     * This function is triggered by an event, retrieves the customer token from the event's
     * current target data attributes, and makes an AJAX request to fetch the edit form for
     * the customer. On success, it displays the form in the `modaleCustomerTarget`. On error,
     * it logs the error data to the console.
     *
     * @param {Event} event - The event triggered by the user's action.
     * @param {HTMLElement} event.currentTarget - The target element that triggered the event.
     * @param {string} event.currentTarget.dataset.token - The customer token from the data attribute.
     */
    showEditCustomer = (event) => {
        const token = event.currentTarget.dataset.token;

        Rails.ajax({
            type: 'get',
            url: `/customers/${token}/edit`,
            data: new URLSearchParams({
                page: "show"
            }),
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => {console.log(data)}
        });
    }

    /**
   * Updates the customer information and displays a confirmation message.
   *
   * This function is triggered by an event, extracts the response data from the event details,
   * updates the `blocInfosTarget` with the new customer information from the AJAX response,
   * updates the `modaleCustomerTarget` with a confirmation message, and calls `handleConfirmationMessage`
   * to handle the display of the confirmation message.
   *
   * @param {CustomEvent} event - The custom event triggered by the user's action.
   * @param {*} event.detail - The details of the event.
   * @param {Array} event.detail[0] - The data returned from the AJAX request.
   * @param {string} event.detail[1] - The status of the AJAX request.
   * @param {XMLHttpRequest} event.detail[2] - The XMLHttpRequest object from the AJAX request.
   */
    updateCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const confirmationMessage =      `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Client mis à jour</h3>
        </div>`;
        this.blocInfosTarget.innerHTML = xhr.response;
        this.modaleCustomerTarget.innerHTML = confirmationMessage;
        handleConfirmationMessage();
    }
    //////

    /**
     * Alerts the user that there are no drawings to download and displays the appropriate message.
     *
     * This function is triggered by an event, extracts the response data from the event details,
     * displays the response in the `modaleCustomerTarget`, and adds a CSS class to the modal for styling.
     *
     * @param {CustomEvent} event - The custom event triggered by the user's action.
     * @param {*} event.detail - The details of the event.
     * @param {Array} event.detail[0] - The data returned from the AJAX request.
     * @param {string} event.detail[1] - The status of the AJAX request.
     * @param {XMLHttpRequest} event.detail[2] - The XMLHttpRequest object from the AJAX request.
     */
    alertNoDrawToDownload(event) {
        const [_data, _status, xhr] = event.detail;
        this.display(xhr.response, this.modaleCustomerTarget);
        this.modaleCustomerTarget.classList.add('modale-new--x-small');
    }

    //Delete method 

    /**
     * Deletes a project and updates the UI with a confirmation message.
     *
     * This function is triggered by an event, retrieves the customer token from the URL, and
     * updates various parts of the UI to reflect the deletion of a project. It also displays a 
     * confirmation message in a modal and handles the necessary UI changes.
     *
     * @param {Event} event - The event triggered by the user's action.
     */
    deleteProject = (event) => {
        
        const userURL = window.location.href.split('/');
        const customerToken = userURL[userURL.length -1];
        let userToken = null;

        this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, customerToken);
        this.getBlocProjects(userToken, customerToken);
        this.getBlocInfos(customerToken);
        
        const confirmationDeleteMessage =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet supprimé</h3>
        </div>`;

        this.modaleCustomerTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleCustomerTarget.innerHTML = confirmationDeleteMessage;
        this.modaleCustomerTarget.classList.add('modale-new--small')
        
        handleConfirmationMessage();

        event.stopPropagation();
        
    }

    deleteMassProjects = (event) => {
        const checkboxMultiple = document.getElementById('multipleCheck')
        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }
        const checkboxes = event.currentTarget.querySelectorAll('input[type=checkbox]');
        const filteredCheckboxes = [...checkboxes].filter( element => element.checked );
        filteredCheckboxes.forEach( element => element.parentNode.parentNode.parentNode.remove() );

        if(this.projectsContainerTarget.querySelector(".project-card") == null) {
            this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer' data-customers-target='disclaimer'>Pas de projet existant</p>";
        }
        this.containerButtonMultipleTarget.style.opacity = "0";
        

        this.decrementProjects(filteredCheckboxes.length);
    }

    /////////

    decrementProjects(number) {
        let counter = parseInt(this.projectsCountTarget.innerHTML);
        this.projectsCountTarget.innerHTML = counter - (number);
    }

    /**
    * Deactivates a customer and updates the UI accordingly.
    *
    * This function is triggered by an event, retrieves the customer ID from the event's current target,
    * checks an icon within the current target, and performs an AJAX request to deactivate the customer.
    * On success, it updates the projects container with the HTML received in the response.
    *
    * @param {Event} event - The event triggered by the user's action.
    */
    desactivateCustomer = (event) => {
        const customerId = event.currentTarget.id;
        const icon = event.currentTarget.querySelector('i').outerHTML ;
        if(icon ==  "<i class='far fa-play-circle'></i>") {
            
        }

        Rails.ajax({
            type: 'get',
            url: `/multiple_desactivate/${customerId}`,
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
            },
            error: (data) => console.log(data)
        })
    }

    /**
    * Deactivates a project and updates the UI accordingly.
    *
    * This function is triggered by an event, finds the closest project card element,
    * updates its HTML content with the new HTML provided in the event details.
    *
    * @param {Event} event - The event triggered by the user's action.
    * @param {*} event.detail - The details of the event.
    * @param {Object} event.detail[0] - An object containing HTML data for updating the project card.
    * @param {string} event.detail[0].html - The HTML content to update the project card.
    */
    desactivateProject = (event) => {
        const card = event.currentTarget.closest('.project-card') || event.currentTarget.closest('.project-card--desactivated');
        card.outerHTML = event.detail[0].html;
    }

    /**
    * Initiates the reassignment process for a project.
    *
    * This function is triggered by an event, retrieves the project token from the event's
    * current target data attributes, and makes an AJAX request to initiate the reassignment
    * process for the project. On success, it displays the response data in the `modaleCustomerTarget`.
    *
    * @param {Event} event - The event triggered by the user's action.
    * @param {HTMLElement} event.currentTarget - The target element that triggered the event.
    * @param {string} event.currentTarget.dataset.token - The project token from the data attribute.
    */
    reassignProject = (event) => {
        const token = event.currentTarget.dataset.token;
       
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/reassign`,
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => console.log(data)
        });
    }

    // Display method 

    /**
    * Displays content in a container and manages related UI interactions.
    *
    * Updates the display style of `overlayTarget` and `container` to flex display,
    * and populates `container` with HTML content from `data`. Handles UI interactions
    * such as closing the modal on button click or overlay click, and adjusts the styling
    * of the modal based on the type of form displayed.
    *
    * @param {Object|string} data - The data or HTML content to display.
    * @param {HTMLElement} container - The container element to display the content in.
    */
    display(data, container) {
        this.overlayTarget.setAttribute('style', 'display: flex');
        container.setAttribute('style','display: flex');
        if(data.html) {
            container.innerHTML = data.html;
        } else {
            container.innerHTML = data;
        }
        
        const button= document.querySelector(".button-close");

        if(button) {
            button.addEventListener("click", (event) => {
                container.setAttribute('style', 'display: none');
                this.overlayTarget.setAttribute('style','display: none');
            });
        }

        if (this.hasPreviewTarget) {
            this.handleInputDraw();
        }

        if(container.querySelector('form')) {
            const form = container.querySelector('form');
            const typeForm = form.dataset.container;

            switch (typeForm) {
                case "form-edit-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleRetailersList();
                    this.handleReferentsList();
                    break;
                case "form-new-project":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    break;
                case "form-reassign":
                case "form-mass-reassign":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                    break;
                default:
                    container.classList = 'modale-new';
            }
        } else {
            container.classList = "modale-new modale-new--small"
        }

        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            container.setAttribute('style','display: none');
        });
    }

    /**
     * Displays or hides a referent form section based on current visibility state.
     *
     * This function is triggered by an event, toggles the display of a form section
     * based on its current visibility (`none` or `flex`). It also adjusts the arrow icon's
     * rotation and scale for visual indication. Additionally, it scrolls the `.form-new` 
     * element to the bottom of its content when expanding the form section.
     *
     * @param {Event} event - The event triggered by the user's action.
     */
    displayReferentForm(event) {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");
        const form = document.querySelector('.form-new');
      
       
        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            form.scrollTo({top: form.offsetHeight, behavior: 'smooth'})

        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }
    }

    /////////////

    /**
    * Appends a newly created project to the UI and updates project counters.
    *
    * This function is triggered by an event, typically related to the creation of a new project.
    * It updates the UI to display a confirmation message in `modaleCustomerTarget`, removes any existing disclaimer,
    * and updates project counters and project list for the current customer.
    *
    * @param {Event} event - The event triggered by the user's action.
    * @param {*} event.detail - The details of the event.
    * @param {Object} event.detail[0] - An object containing data related to the event.
    * @param {string} event.detail[0].html - The HTML content related to the newly created project.
    */
    appendProject = (event) => {
        const [_data, _status, xhr] = event.detail;
        const disclaimer = this.projectsContainerTarget.querySelector('.disclaimer-projects');
        const url = window.location.href.split('/');
        const customerToken = url[url.length - 1];
        
        if (disclaimer) {
            disclaimer.remove();
        }

        this.modaleCustomerTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>Projet crée</h3>
        </div>
        `;
        handleConfirmationMessage();

        this.handleCounter('projects', this.projectsCounterContainerTarget, null, customerToken);
        this.getBlocProjects(null, customerToken);
    }

    /**
    * Display a form for the mass reassignment of selected projects.
    * 
    * Retrieves the tokens of all checked input elements, sends a request to the server
    * to initiate a mass reassignment process for the selected projects, and displays 
    * the response in the modal customer target.
    */
    massReassign = () => {
        const tokens = [];

        document.querySelectorAll('input:checked').forEach((element) => {
            tokens.push(element.value)
        });
        
        Rails.ajax({
            type: 'get',
            url: "/projects/multiple_reassign_edit",
            data: new URLSearchParams({
                project_tokens: tokens
            }),
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => {console.log(data)}
        })
    }

    /**
    * Sets up event handling for input changes related to customer logo.
    * 
    * Attaches an event listener to the 'change' event of the input element with ID 'customer_logo'.
    * When the input changes, it calls {@link updateDisplayImage} to update the displayed image in the preview target.
    */
    handleInputDraw = () => {
        
        let preview = this.previewTarget;
        let input = document.getElementById('customer_logo');
        input.addEventListener('change',() => {
            this.updateDisplayImage(preview, input);
        })
    }

    /**
    * Updates the display of selected files in a preview element.
    *
    * This function clears any existing content from the preview element,
    * checks if any files are selected in the input element, and displays
    * either a message indicating no files are selected or a list of the
    * names of the selected files.
    *
    * @param {HTMLElement} preview - The DOM element where the file preview will be displayed.
    * @param {HTMLInputElement} input - The file input element containing the selected files.
    */
    updateDisplayImage = (preview, input) => {
        while (preview.firstChild) {
            preview.removeChild(preview.firstChild);
        }
        const curFiles = input.files;
        if (curFiles.length === 0) {
            let para = document.createElement("p");
            para.textContent = "Aucun fichier séléctionné"
            preview.appenChild(para);
        }
        else {
            const list = document.createElement('div');
            preview.appendChild(list);
            for(let i = 0; i < curFiles.length; i++) {
                let para = document.createElement('p');
                para.style.margin = "unset";
                para.textContent = curFiles[i].name;
                list.appendChild(para);
            }
        }
    }

 
    handleCheckbox(event) {
        handleCheckbox(event);
    }

    handleCloseButton() {
     
        this.buttonCloseTarget.addEventListener("click", () => {
            this.modaleCustomerTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
        
    }

    // Error method

    errorNew = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.modaleCustomerTarget.innerHTML = xhr.response; 

        this.handleCloseButton();
        openCollapse();
    }
    ////////////////

    // Tools

    /**
    * Handles the dynamic loading of retailers and referents lists based on the selected organization.
    *
    * This function adds an event listener to the organization input element. When the organization changes,
    * it triggers two AJAX requests:
    * - The first request retrieves a list of retailers for the selected organization and updates the retailer input element.
    * - The second request retrieves a list of referents for a null retailer ID (or a specific one) and updates the referent input element.
    */
    handleRetailersList = () => {
        if(this.hasInputOrganizationTarget && this.hasInputRetailerTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                const organizationId = event.currentTarget.value;

                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationId}/retailers_list`,
                    success: (data) => {
                 
                        this.inputRetailerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
               
                const retailerId = null
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    data: new URLSearchParams({
                        organization_id: organizationId
                    }),
                    success: (data) => {
                        
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                }); 
            })
        }
    }

    /**
    * Handles the dynamic loading of the referents list based on the selected retailer.
    *
    * This function adds an event listener to the retailer input element. When the retailer changes,
    * it triggers an AJAX request to retrieve the list of referents associated with the selected retailer
    * and updates the referent input element with the received data.
    */
    handleReferentsList = () => {
        if(this.hasInputRetailerTarget) {
            this.inputRetailerTarget.addEventListener("change", (event) => {
                const retailerId = event.currentTarget.value;
                this.referentFieldTarget.classList.remove('hidden')
                
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    success: (data) => {
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                });
            });
        }
    }

    /**
    * Checks if retailers exist for the selected organization and displays the appropriate data.
    *
    * This function adds an event listener to the organization input element. When the organization changes,
    * it triggers an AJAX request to check if there are any retailers associated with the selected organization.
    * If successful, it calls the `display` function to update the target modal with the retrieved data.
    */
    checkIfRetailersExist = () => {
        this.inputOrganizationTarget.addEventListener('change', () => {
            const organizationId = this.inputOrganizationTarget.value;

            Rails.ajax({
                type :'get',
                url: `/organizations/${organizationId}/customers/new`,
                success: (data) => this.display(data, this.modaleNewDashboardTarget),
                error: (data) => console.log(data)
            })
        })
    }

    /**
    * Sorts items based on the selected column and updates the display accordingly.
    *
    * This function is triggered when a sortable column header is clicked. It retrieves necessary data
    * (like the item type, column to sort by, and sorting direction), displays a loader, and sends an AJAX
    * request to sort the items on the server side. After receiving the sorted data, it updates the display
    * and adjusts the sort arrow accordingly.
    *
    * @param {Event} event - The event triggered by clicking on a sortable column header.
    */
    sortItems = (event) => {
        this.displayLoader();
        const item = event.currentTarget.dataset.item;
        const column = event.currentTarget.dataset.column;
        const arrow = event.currentTarget.querySelector("i");
        const container = document.querySelector(`.${item}s-cards-container`);
        let cssClass = "rotate--visible";
        let userToken = null;

        //Get customer token
        const url = window.location.href.split('/');
        const customerToken = url[url.length - 1];

        const organizationId = event.currentTarget.dataset.organization
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");

        //Get selected cards for display
        const blocIndex = event.currentTarget.parentNode;
        const sortableItems = Array.from(blocIndex.querySelectorAll(".sortable"));
        const itemNotSelected = sortableItems.filter(element => element != event.currentTarget);
        

        itemNotSelected.forEach((element) => {
            if(element.querySelector("i").classList.contains("rotate--visible") || element.querySelector("i").classList.contains("rotate--180")) {
                sortableItems.forEach(element => element.querySelector("i").classList = "fas fa-arrow-down rotate")
            }
        });

        if(event.currentTarget.querySelector("i").classList.contains("rotate--visible")) {
             cssClass = "rotate--180";
        }
        else if(event.currentTarget.querySelector("i").classList.contains("rotate--180")) {
            cssClass = "";
        }
       
        
        Rails.ajax({
            type: 'get',
            url: `/launch_sort_${item}s`,
            data: new URLSearchParams({
                item: item,
                column: column,
                css_class: cssClass,
                selected_organization: selectedOrganization,
                organization_id: organizationId,
                user_token : userToken,
                customer_token: customerToken
            }),
            success:(data) => {
                this.removeLoader();
                container.innerHTML = data.html;
                arrow.classList = `fas fa-arrow-down rotate ${cssClass}`;
             },
            error: (data) => { console.log(data) }
        })
    }

    displayLoader() {
        this.overlayTarget.style.display = "block";
    }

    removeLoader() {
        this.overlayTarget.style.display = "none";
    }

    removeSortItem = () => {
        const items = document.querySelectorAll(".rotate");
        items.forEach( item => item.classList = "fas fa-arrow-down rotate")
    }

    // API calls

    /**
    * Retrieves block information for a given customer and updates the target element's content.
    *
    * @param {string} customerToken - The token used to identify the specific customer.
    */
    getBlocInfos(customerToken) {
        const customer = customerToken;

        Rails.ajax({
            type: 'get',
            url: `/customers/${customer}/get_bloc_infos`,
            success: (data) => {
                this.blocInfosTarget.innerHTML = data.html;
            },
            error: (data) => { console.log(data) }
        })
    }

    /**
    * Handles the counter update based on the specified type and updates the UI accordingly.
    *
    * @param {string} type - The type of counter to handle (e.g., 'projects').
    * @param {HTMLElement} counterContainer - The HTML container element where the counter value will be displayed.
    * @param {string} selectedUser - The selected user identifier.
    * @param {string} selectedCustomer - The selected customer identifier.
    */
    handleCounter = (type, counterContainer, selectedUser, selectedCustomer) => {
        switch (type) {
            case 'projects':
            Rails.ajax({
                type: 'get',
                url: '/projects/get_counter_projects',
                data: new URLSearchParams({
                    selected_customer: selectedCustomer,
                    selected_user: selectedUser
                }),
                success: (data) => {
                    counterContainer.innerHTML = data.html
                    this.customerSelectedBlocProjectsTarget.style.display = "none";
                }
            });
            break;
            default: null
        } 

    }

    /**
    * Retrieves project data for a specific user and customer, and updates the UI accordingly.
    *
    * @param {string} userToken - The token used to identify the specific user.
    * @param {string} customerToken - The token used to identify the specific customer.
    */
    getBlocProjects = (userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/projects`,
            data: new URLSearchParams({
                user_token: userToken,
                customer_token: customerToken
            }),
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.projectsLegendTarget);

                if(this.projectsContainerTarget.querySelector(".project-card") == null) {
                    this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de projet existant</p>";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    ////////////
}