import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Sortable from "sortablejs";

export default class extends Controller { 
    static targets = [];

    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        });
    }

    end(event) {
        const order = [];
        const elements = event.target.children;
        const drawId = event.item.dataset.draw;

        for (let item of elements) {
            order.push(item.dataset.attachmentId);
        }
        
        Rails.ajax({
            type: 'put',
            url: `/draws/${drawId}/reorder_plans_attachments/${order}`
        })

    }

  
}