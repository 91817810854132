// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "chartkick"
import "controllers"


Rails.start()

ActiveStorage.start()


import { handleDrawGroupClick, selectDefaultGroupCard, showDefaultDraws } from '../pages/showProject';
import { hideModaleInformationsDraw } from '../pages/showDraw';
import { handleFlashMessage } from "../utils/reload"

import { showDialog, drawChart } from '../pages/dashboard.js';



document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  event.preventDefault();
});


document.addEventListener('turbo:load', () => {
  handleDrawGroupClick();
  hideModaleInformationsDraw();
  handleFlashMessage();
  
  google.charts.load('current', {'packages':['corechart']});
  google.charts.setOnLoadCallback(drawChart);
  
})




