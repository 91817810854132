import { Dropzone } from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
    getMetaValue,
    toArray,
    findElement,
    removeElement,
    insertAfter
} from "helpers";
import { handleScroll, disabledCreateButton, enabledCreateButton } from "../utils/tools";



export default class extends Controller {
    static targets = ["input", "dropzoneMessage", "counterAttachments"];

    connect() {
        this.dropzone = createDropZone(this);
        this.hideFileInput();
        this.bindEvents();
        Dropzone.autoDiscover = false;
    }

 
    //Private
    hideFileInput() {
        this.inputTarget.disabled = true;
        this.inputTarget.style.display = "none";
    }

    hideMessage() {
        this.dropzoneMessageTarget.style.display = "none";
    }

    showMessage() {
        this.dropzoneMessageTarget.style.display = "flex";
    }

    handleCounterAttachment(dropzone) {
        const containerCounter = dropzone.element.parentElement.querySelector('.new-form-element__counter');
        const totalFileCounter = dropzone.element.parentElement.querySelector('.total-file-counter');
        
        if(containerCounter && totalFileCounter) {
            let counter = dropzone.files.length;
            let expectedFile = dropzone.element.parentElement.querySelector('.expected-file');
            totalFileCounter.innerHTML = counter;
            
            
            if (counter > parseInt(expectedFile.innerHTML)) {
                containerCounter.setAttribute('style', 'color: red');
            } 
            else if (counter == parseInt(expectedFile.innerHTML)) {
                containerCounter.setAttribute('style', 'color: green')
            }   
            else {
                containerCounter.setAttribute('style', 'color: black');
            }
        }
    }

    bindEvents() {
        this.dropzone.on("addedfile", file => {
            setTimeout(() => {
                if (file.accepted) {
                    createDirectUploadController(this, file).start();
                }
                else {
                    handleScroll(file.previewElement.parentElement.dataset.dropzone);
                    enabledCreateButton();
                }
               
            }, 500);
          
            disabledCreateButton();
            this.hideMessage();

            if(this.dropzone.element.parentElement.querySelector('.new-form-element__wrapper-form__counter-container')) {
                this.handleCounterAttachment(this.dropzone);
            }
            
         
        });

        this.dropzone.on("removedfile", file => {
            const label = this.element.parentElement.querySelector('label');
            const errorMessage = label.querySelector('.error');
            if(errorMessage) {
                if(this.element.querySelector('.dz-error') == null) {
                    errorMessage.remove();
                }
            }
          
            if(this.dropzone.files.length === 0) {
                this.showMessage();
            }
           
            file.controller && removeElement(file.controller.hiddenInput);
            
            if(this.dropzone.element.parentElement.querySelector('.new-form-element__wrapper-form__counter-container')) {
                this.handleCounterAttachment(this.dropzone);
            }
        });

        this.dropzone.on("canceled", file => {
            file.controller && file.controller.xhr.abort();
        });
    }

    get headers() {
        return { "X-CSRF-Token": getMetaValue("csrf-token") };
      }
    
    get url() {
        return this.inputTarget.getAttribute("data-direct-upload-url");
    }

    get maxFiles() {
        return this.data.get("maxFiles") || 1;
    }

    get addRemoveLinks() {
        return this.data.get("addRemoveLinks") || true;
    }


}

class DirectUploadController {
    constructor(source, file) {
        this.directUpload = createDirectUpload(file, source.url, this);
        this.source = source;
        this.file = file;
    }

    start() {
        this.file.controller = this;
        this.hiddenInput = this.createHiddenInput();
        this.directUpload.create((error, attributes) => {
            if(error) {
                console.log(error);
                removeElement(this.hiddenInput);
                this.emitDropzoneError(error);
            } else {
                this.hiddenInput.value = attributes.signed_id;
                this.emitDropzoneSuccess();
                handleScroll(this.file.previewElement.parentElement.dataset.dropzone);
            }
        });
    }

    createHiddenInput() {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = this.source.inputTarget.name;
        insertAfter(input, this.source.inputTarget);
        return input;
    }

    directUploadWillStoreFileWithXHR(xhr) {
        this.bindProgressEvent(xhr);
        this.emitDropzoneUploading();
    }

    bindProgressEvent(xhr) {
        this.xhr = xhr;
        this.xhr.upload.addEventListener("progress", (event) => {
            this.uploadRequestDidProgress(event);
        });
    }

    uploadRequestDidProgress(event) {
        const element = this.source.element;
        const progress = (event.loaded / event.total) * 100;
        
        findElement(
            this.file.previewTemplate,
            ".dz-upload"
        ).style.width = `${progress}%`;
    } 

   emitDropzoneUploading() {
        this.file.status = Dropzone.UPLOADING;
        this.source.dropzone.emit("processing", this.file);
    }

    emitDropzoneError(error) {
        this.file.status = Dropzone.ERROR;
        this.source.dropZone.emit("error", this.file, error);
        this.source.dropZone.emit("complete"), this.file;
    }

    emitDropzoneSuccess() {
        this.file.status = Dropzone.SUCCESS;
        this.source.dropzone.emit("success", this.file);
        this.source.dropzone.emit('complete', this.file);
        this.file.previewElement.querySelector('.dz-success-mark').setAttribute('style', 'display: flex');
        let status = []
        this.source.dropzone.files.forEach((el) => {
            status.push(el.status)
        })
        if(!status.includes('uploading')) {
            enabledCreateButton();
        }
    }


}

function createDirectUploadController(source, file) {
    return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
    return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
    return new Dropzone(controller.element, {
        url: controller.url,
        header: controller.headers,
        maxFilesize: 15,
        maxFiles: controller.maxFiles,
        acceptedFiles: ".png, .jpg, .jpeg, .pdf, .svg, .gif",
        addRemoveLinks: controller.addRemoveLinks,
        autoQueue: false,
        autoProcessQueue: true,
        dictFileTooBig: "Fichier trop lourd ! ({{filesize}}MB. Maximum: {{maxFilesize}}MB)",
        dictInvalidFileType: "Format de fichier incorrect",
        dictRemoveFile: "Retirer le fichier",
        dictMaxFilesExceeded: "Vous ne pouvez pas télécharger autant de fichiers",
        dictCancelUpload: "Annuler",
        thumbnailWidth: 60,
        thumbnailHeight: 60,
        thumbnailMethod: "contain",
        parallelUploads: 4,
        uploadMultiple: true,
        clickable: [controller.element, controller.element.querySelector('.dropzone-message')]
    });
}

