import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["modaleShow", "video", "buttonPlay", "modaleInformations", "modaleMedia", "floorBox", "drawsContainer", "mmodaleMedia"];

    connect() {
    }

 

    showModaleVideo = (event) => {
        this.modaleMediaTarget.style.display = "block";
        const type = event.currentTarget.dataset.type;

        switch(type) {

            case 'incendie':
                let video =   ` <div class="modale-media__close">
                                    <i class="fas fa-times-circle" data-action="click->previsualizations#hideModale"></i>
                                </div>
                                <video data-previsualizations-target="video" autoplay="true">
                                    <source src="https://res.cloudinary.com/qrd/video/upload/v1681994217/Consignes_Incendie_mbwhpt.webm" type="video/webm">
                                    <source src="https://res.cloudinary.com/qrd/video/upload/v1681994217/Consignes_Incendie_mbwhpt.mp4" 
                                    type="video/mp4"><source src="https://res.cloudinary.com/qrd/video/upload/v1681994217/Consignes_Incendie_mbwhpt.ogv" 
                                    type="video/ogg">
                                </video>
                            `;
                this.modaleMediaTarget.innerHTML = video;
                
           
            break;

            case 'evacuation':
                video =   `
                                <div class="modale-media__close">
                                    <i class="fas fa-times-circle" data-action="click->previsualizations#hideModale"></i>
                                </div>
                                <video data-previsualizations-target="video" autoplay="true">
                                    <source src="https://res.cloudinary.com/qrd/video/upload/v1681994212/Consignes_e%CC%81vacuation_zsrsha.webm" type="video/webm">
                                    <source src="https://res.cloudinary.com/qrd/video/upload/v1681994212/Consignes_e%CC%81vacuation_zsrsha.mp4" 
                                    type="video/mp4"><source src="https://res.cloudinary.com/qrd/video/upload/v1681994212/Consignes_e%CC%81vacuation_zsrsha.ogv" 
                                    type="video/ogg">
                                </video>
                            `;
                this.modaleMediaTarget.innerHTML = video;
               
            break; 

            case 'accident':
                video =   ` 
                            <div class="modale-media__close">
                                <i class="fas fa-times-circle" data-action="click->previsualizations#hideModale"></i>
                            </div>
                            <video data-previsualizations-target="video" autoplay="true">
                                    <source src="https://res.cloudinary.com/qrd/video/upload/v1681994209/Consignes_Accident_nnz5fk.webm" type="video/webm">
                                    <source src="https://res.cloudinary.com/qrd/video/upload/v1681994209/Consignes_Accident_nnz5fk.mp4" 
                                    type="video/mp4"><source src="https://res.cloudinary.com/qrd/video/upload/v1681994209/Consignes_Accident_nnz5fk.ogv" 
                                    type="video/ogg">
                            </video>
                        `;
                this.modaleMediaTarget.innerHTML = video;
              
            break;

            default:
                console.log(error);
        } 
    }

    showGatheringPoint = (event) => {
        const url = event.currentTarget.dataset.media;
        let content = ``;
        if(url != undefined) {
            content = 
            `<div class="modale-media__close">
                <i class="fas fa-times-circle" data-action="click->previsualizations#hideModale"></i>
            </div>
            <img src=${url} alt="point de rassemblement"/>
            `;
        }
        else {
             content = 
            `<h2 class="gathering-point__disclaimer">Aucun point de rassemblement renseigné</h2>
            <i class="fas fa-times-circle" data-action="click->previsualizations#hideModale"></i>`;
        }

        this.modaleMediaTarget.style.display = "flex";
        this.modaleMediaTarget.innerHTML = content;
     
    }

    showInformations = () => {
        this.modaleInformationsTarget.style.display = "flex";
        
    }

    hideModale() {
       const video = document.querySelector("video");
        if(video) {
            this.videoTarget.pause();
        }
        this.modaleInformationsTarget.style.display = "none";
        this.modaleMediaTarget.style.display = "none";
    }

    openDraw = (event) => {
        const url = event.currentTarget.dataset.media;
        const plan = `
                    <div class="modale-media__close">
                        <i class="fas fa-times-circle" data-action="click->previsualizations#hideModale"></i>
                    </div>
                    <img src=${url} alt="file"/>
                    `;
        this.modaleMediaTarget.style.display = "flex";
        this.modaleMediaTarget.innerHTML = plan;
    }

    handleSelectedFloor = (event) => {
        const url = event.currentTarget.dataset.media;
        this.floorBoxTargets.forEach((floorBox) => {
            floorBox.classList = "select-floor-container__floor";
        });
        event.currentTarget.classList.add("select-floor-container__floor--selected");
        this.drawsContainerTarget.innerHTML = `<img src=${url} class='draw'/>`;
        this.drawsContainerTarget.dataset.media = url;
    }
}