import Rails from '@rails/ujs';
import Swal from 'sweetalert2';


export function drawChart() {
  const containers = document.querySelectorAll(".statistics-container__charts");

  containers.forEach((container) => {

      const id = container.firstElementChild.id;
      const type = container.firstElementChild.dataset.type;
   

      if(type == 'pie') {
          var chart = new google.visualization.PieChart(document.getElementById(`${id}`));

          var options = {
              pieHole: 0.4,
              colors: ["#039300", "#40A236", "#52AA49", "#8BC383"],
              legend: 'none',
              height: "250",
              width: "250"
          };

          var val = JSON.parse(chart.container.dataset.data);
          var arrayData = Object.entries(val)
        
          var labels = ["Name", "Number"];
          arrayData.splice(0, 0, labels);

          var data = google.visualization.arrayToDataTable(
              arrayData
          ); 
          chart.draw(data, options);
      }
  });
}


export function showDialog(element, onConfirm) {
    const options = JSON.parse(element.getAttribute('data-custom-alert') || '{}');
    const message = element.getAttribute('data-confirm');

    Swal.fire({
        text: message,
        icon: 'warning',
        title: 'Attention',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        iconColor: '#FF0000',
        cancelButtonText: "Annuler",
        ...options
      }).then((result) => {
        if (result.isConfirmed) {
          onConfirm();
        }
      });
}


const nativeConfirm = Rails.confirm;
let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  

    if (__SkipConfirmation) {
        return true;
    }

    const dialogId = element.getAttribute('data-custom-alert');

    if (!dialogId) {
        return nativeConfirm(message, element);
    }
 

    function onConfirm() {
        __SkipConfirmation = true;
        element.click();
        __SkipConfirmation = false;
    }

    showDialog(element, onConfirm);
    return false;
}
