const showMultipleButtons = (event, container) => {
    const wrapper = document.getElementById(`${container}`);
    const containerButtons = wrapper.querySelector(".container-button-multiple");

    if(event.currentTarget.checked) {
        containerButtons.style.opacity = "1";
        containerButtons.style.zIndex = "0";
    }

    else if(event.currentTarget.checked == false && wrapper.querySelectorAll('input:checked').length === 0 ) {
        containerButtons.style.opacity = "0";
        containerButtons.style.zIndex = "-1";
    }
}

const checkMultiCheckbox = (container) => {
    const wrapper = document.getElementById(`${container}`);
    const checkboxes = wrapper.querySelectorAll("input[type=checkbox]");
    const arr = Array.from(checkboxes);

    if(arr[0].checked) {
        arr.forEach((checkbox) => {
            checkbox.checked = true;
        })
    } else {
        arr.forEach((checkbox) => {
            checkbox.checked = false;
        } )
    }
}

const handleCheckbox = (event) => {
    const container = event.currentTarget.dataset.container;

    if(event.currentTarget.id == "multipleCheck") {
        checkMultiCheckbox(container);
        showMultipleButtons(event, container);
    }
    else {
        showMultipleButtons(event, container)
    }
}

const formatToUpperCaseCamelCase = (value) => {
    value = value.split('');
    const firstLetterFormated = value[0].toUpperCase();
    value.splice(0, 1);
    value.unshift(firstLetterFormated);
    const indexUppercase = value.indexOf('_') + 1;

    if(indexUppercase != 0) {
        let upperCaseLetter = value[indexUppercase];
        upperCaseLetter = upperCaseLetter.toUpperCase();
        value.splice(indexUppercase - 1, 1, upperCaseLetter)
        value.splice(indexUppercase , 1)
    }
  
    return value.join('');

}

const handleScroll = (type) => {
    let element = null;
    switch (type) {
        case "dzDocuments":
            element = document.getElementById('container-documents')
            element.scrollIntoView({behavior: 'smooth', block: 'end'});
            break;
        case "dzPlans":
            element = document.querySelector('.collapse');
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
            break;
        case "dzAccessDraws":
            element = document.getElementById('container-access-plans');
            element.scrollIntoView({behavior: 'smooth', block: 'end'});
            break;
    }
}

const disabledCreateButton = () => {
    document.querySelector('.button-1').classList.add('button-1--disabled');
}

const enabledCreateButton = () => {
    document.querySelector('.button-1').classList.remove('button-1--disabled');
}

const openCollapse = () => {
    const collapses = document.querySelectorAll('.collapse');
    collapses.forEach((collapse) => {
        const arrow = collapse.querySelector('i');
        const wrapper = collapse.querySelector('.collapse__wrapper');
    
        if(wrapper.querySelector('.validation') || wrapper.querySelector('.field_with_errors')) {
            wrapper.style.display = "flex";
            if(arrow) {
                arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            }
            
            collapse.scrollIntoView({behavior: "smooth", block: 'end'});
        }
    })
}

const updateModalSize = (container, form) => {
    if (container.classList.contains("modale-new--small")) {
        container.classList.remove('modale-new--small');
        document.querySelector('.form-new').classList.remove('form-new--small')
    } else {
        container.classList.add('modale-new--small');
        document.querySelector('.form-new').classList.add('form-new--small')
    }
}

const getUrlParams = (id) => {
    let params = null;
    const baseURL = window.location.origin;
    if(document.getElementById(id)) {
        const userURL = new URL(document.getElementById(id).src, baseURL);
        params = new URLSearchParams(userURL.search);
    }
    return params;
}






export { showMultipleButtons, checkMultiCheckbox, handleCheckbox, formatToUpperCaseCamelCase, handleScroll, disabledCreateButton, enabledCreateButton, openCollapse, updateModalSize, getUrlParams }