import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { selectDefaultGroupCard, handleDrawGroupClick } from "../pages/showProject";
import { handleConfirmationMessage } from "../utils/reload";
import { openCollapse } from "../utils/tools.js";

export default class extends Controller { 
    static targets = ["interveningsUpdateContainer", "overlayEditIntervening", "modalEditIntervening", "interveningsArrow", "containerIntervenings",
    "preview", "containerIndexIntervenings", "buttonChangeIntervenings", "newTypeContainer", "formIntervening", "containerGatheringPoint",
    "displayPlanContainer", "modaleDrawGroup"];

    connect() {

    }

   
    showOptionFormIntervenings = (event) => {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");
        const form = document.querySelector('.form-new');

        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            form.scrollTo({top: form.offsetHeight, behavior: 'smooth'})
        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }

    }

   /*  displayFormIntervenings = (event) => {
        event.currentTarget.remove();
        const indexIntervening = this.containerIndexInterveningsTarget.querySelectorAll(".wrapper-form-intervening__bloc").length;
        const indexTypeIntervening = indexIntervening - 3;

        Rails.ajax({
            type: 'get',
            url: `/generate_new_input`,
            data: new URLSearchParams({
                index_intervening: indexIntervening,
                index_type_intervening: indexTypeIntervening
            }),
            success: (data) => {
                this.displayNewInput(data);
                this.containerIndexInterveningsTarget.scrollIntoView({behavior: "smooth", block: "end"});
            },
            error: (data) => {console.log(data)}
        })
    } */

    editIntervening = (event) => {
        const interveningId = event.currentTarget.id;

         Rails.ajax({
            type: 'get',
            url: `/intervenings/${interveningId}/edit`,
            success: this.displayEditIntervening.bind(this),
            error: (data) => {console.log(data)}
        });
    }


    addNewTypeIntervening(event) {
        
        Rails.ajax({
            type: 'get',
            url: `/generate_new_input`,
            success: (data) => {
                this.displayNewInput(data);
                this.containerIndexInterveningsTarget.scrollIntoView({ behavior: "smooth", block: "end" });
            },
            error: (data) => {console.log(data)}
        })
    }

    displayNewInput(data) {
        this.containerIndexInterveningsTarget.insertAdjacentHTML('beforeend', data.html);
    }

    displayEditIntervening = (data) => {
        this.overlayEditInterveningTarget.style.display = "flex";
        this.modalEditInterveningTarget.style.display = "flex";
        this.modalEditInterveningTarget.innerHTML = data.html;

        this.overlayEditInterveningTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            this.modalEditInterveningTarget.setAttribute('style','display: none');
        })
    }

    hideEditIntervening = () => {
        this.overlayEditInterveningTarget.style.display = "none";
        this.modalEditInterveningTarget.style.display = "none";
    }

    appendIntervening = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.interveningsUpdateContainerTarget.innerHTML = xhr.response;
        this.hideEditIntervening();
    }

    appendDrawGroup = (event) => {
        const drawGroupCards = Array.from(document.querySelectorAll('.draw-group-card'));
        let drawGroupCardIndex = null;
        const confirmationEditMessage = `
        <div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Groupe de plans mis à jour</h3>
        </div>`;

        drawGroupCards.forEach((el) => {
            if(el.classList.contains('draw-group-card--selected')) { 
                drawGroupCardIndex = drawGroupCards.indexOf(el);
            }
        })

        const modale = document.querySelector(".modale-new");
        const container = document.querySelector(".draw-group-container");
        const overlay = document.querySelector(".overlay");
        const [_data, _status, xhr] = event.detail;
        container.innerHTML = xhr.response;
        modale.innerHTML = confirmationEditMessage;
        modale.classList = "modale-new modale-new--small"

        handleConfirmationMessage();
        selectDefaultGroupCard(drawGroupCardIndex);
        handleDrawGroupClick();
        container.scroll(0, 0);
    }

    deleteGatheringPoint = (event) => {
        const drawGroupId = event.currentTarget.dataset.drawgroup;
       
        Rails.ajax({
            type: 'delete',
            url: `/draw_groups/${drawGroupId}/delete_gathering_point_attachment`,
            success: () => {
                this.containerGatheringPointTarget.remove();
            },
            error: (data) => console.log(data)
        })
    }

    displayGatheringPoint = (event) => {
        const drawGroupId = event.currentTarget.dataset.drawgroup;
        const modale = document.getElementById('display-plan-container');
       
        Rails.ajax({
            type: 'get',
            url: `/draw_groups/${drawGroupId}/display_gathering_point`,
            success: (data) => {
                modale.setAttribute('style','display: flex');
                modale.innerHTML = data.html;
            },
            error: (data) => console.log(data)
        })
    }

    //Form method


    error(event) {
        const [_data, _status, xhr] = event.detail;
        this.modaleDrawGroupTarget.outerHTML = xhr.response;
        openCollapse();
    } 

 

    errorIntervening(event) {
        const id = event.currentTarget.id;
        const [_data, _status, xhr] = event.detail;
        this.modalEditInterveningTarget.innerHTML = xhr.response; 
    }
}